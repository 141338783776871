import { ChangeBillingAccountDialog } from '../ChangeBillingAccountDialog/ChangeBillingAccountDialog.js';
import { EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import { FormProvider, useForm } from 'react-hook-form';
import { RedeemOptionDetails } from './EppRedeemOptionDetails.js';
import { RedeemOptions } from './EppRedeemOptions.js';
import { WizardActions } from '../WizardActions/index.js';
import { WizardType } from '../../common/enums.js';
import {
  cancelMsg,
  eppRedeemOptionTitleMsg,
  forTheCompanyMsg,
  forTheEmployeeMsg,
  redeemIsBinding,
  t,
} from '../../common/i18n/index.js';
import { useEppRedeemForwardClick } from './EppRedeemUtils.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import type { Subscription } from '../../generated/api/models.js';

import './EppRedeem.scss';

export interface EppRedeemAdminProps {
  companyName?: string;
  onBackClick: () => void;
  subscription: Subscription;
}

export const EppRedeemAdmin = ({ companyName, onBackClick, subscription }: EppRedeemAdminProps) => {
  const location = useLocation();
  const { pathname, search, state } = location;
  const queryParams = new URLSearchParams(search);
  const currentPath = `${pathname}?${queryParams}`;
  const navigate = useNavigate();
  const [redeemType, setRedeemType] = useState<EppRedeemTerminateRequestType>(
    EppRedeemTerminateRequestType.COMPANY_REDEEM
  );
  const [billingAccountId, setBillingAccountId] = useState(subscription.billingAccountId);
  const [billingAccountDisplayId, setBillingAccountDisplayId] = useState(subscription.billingAccountDisplayId);
  const methods = useForm();

  const forwardClick = useEppRedeemForwardClick({
    billingAccountId,
    isRedeemedByAdmin: true,
    redeemType,
    subscription,
    companyName,
  });

  return (
    <div className="of-epp-redeem">
      {state?.type === WizardType.CHANGE_EPP_REDEEM_BILLING_ACCOUNT && (
        <ChangeBillingAccountDialog
          billingAccountId={billingAccountId!}
          headerText={t.H41V('Change invoicing agreement')}
          description=""
          detailedView={true}
          displayAddNewOption={false}
          searchable={true}
          onCloseDialog={() => {
            navigate(currentPath, { replace: true, state: { newBillingAccountId: state?.newBillingAccountId } });
          }}
          onSubmit={(updatedBillingAccountId: string, updatedBillingAccountDisplayId: string) => {
            if (updatedBillingAccountId !== billingAccountId) {
              setBillingAccountId(updatedBillingAccountId);
              setBillingAccountDisplayId(updatedBillingAccountDisplayId);
            }
            navigate(currentPath, { replace: true, state: { newBillingAccountId: state?.newBillingAccountId } });
          }}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(forwardClick)}>
          <div className="ds-padding-top--2 ds-padding-bottom--5">
            {`${t.D9NS(
              `You can redeem the device for the company or make a redemption request on behalf of the employee in case an employee has Omalaitelasku in use. The redemption price is calculated in real time. The employee can also redeem the device himself / herself from the Employee's OmaElisa at yrityksille.elisa.fi/työntekijä.`
            )} ${t.UNYV(redeemIsBinding)}`}
          </div>
          {subscription.details?.device?.omaLaiteLasku && (
            <RedeemOptions
              titleText={t.VJNB(eppRedeemOptionTitleMsg)}
              companyLabel={t.VS0Z(forTheCompanyMsg)}
              employeeLabel={t.ACGC(forTheEmployeeMsg)}
              onChange={(selectedType: EppRedeemTerminateRequestType) => setRedeemType(selectedType)}
              selectedOption={redeemType}
            />
          )}
          <RedeemOptionDetails
            billingAccountId={billingAccountId}
            billingAccountDisplayId={billingAccountDisplayId}
            subscription={subscription}
            selectedOption={redeemType}
            companyName={companyName}
          />
          <hr className="ds-margin-vertical--4" />
          <WizardActions
            forwardButtonText={t.I62A('Continue')}
            backButtonText={t.B2V1(cancelMsg)}
            onForwardClick={() => undefined}
            onBackClick={onBackClick}
          />
        </form>
      </FormProvider>
    </div>
  );
};
