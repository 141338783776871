import type { AddOn } from '../../generated/api/addOn.js';
import type { CommercialProductAddOnVisibilitiesMap } from '../../common/utils/addOnVisibilityUtils.js';

const addOnCodes = Object.freeze({
  onlineInstallationServiceAddOnCode: '83cda4e8-7131-737a-924d-db1d082019d2',
  yritysWifiAddOnCode: '6db0d5a4-8065-20be-69cd-20c82b429391',
  yritysWifiDeliveryFeeAddOnCode: 'fc6e7266-0c72-a92b-395c-5a42917684ad',
  yritysWifiInstallationServiceAddOnCode: '88f35402-53f8-dc9e-122e-e900b28876f1',
  comboInstallationServiceAddOnCode: '61a5fd4c-951d-aba8-9f92-001f712a3e7e',
});

export const separatelyHandledFixedBroadbandAddOnCodes: string[] = Object.values(addOnCodes);

const findAddOn = (
  addOnCode: string,
  addOnProductName: string,
  commercialProductCode?: string,
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap
) => {
  if (!(commercialProductCode && commercialProductAddOnVisibilities)) {
    return undefined;
  }

  const allowedAddOns = commercialProductAddOnVisibilities?.[commercialProductCode];
  const addOns = allowedAddOns?.[addOnProductName];

  return addOns?.find(a => a.addOnCode === addOnCode);
};

export const findOnlineInstallationService = (
  commercialProductCode?: string,
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap
): AddOn | undefined => {
  const installationServiceAddOnProductName = 'Asennuspalvelu';

  return findAddOn(
    addOnCodes.onlineInstallationServiceAddOnCode,
    installationServiceAddOnProductName,
    commercialProductCode,
    commercialProductAddOnVisibilities
  );
};

export const findYritysWifiService = (
  commercialProductCode?: string,
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap
) => {
  const yritysWifiAddOnProductName = 'Wifi Mesh-palvelu';

  return findAddOn(
    addOnCodes.yritysWifiAddOnCode,
    yritysWifiAddOnProductName,
    commercialProductCode,
    commercialProductAddOnVisibilities
  );
};

export const findYritysWifiDeliveryFeeAddOn = (
  commercialProductCode?: string,
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap
) => {
  // Because of Salesforce product structure, the one-off delivery for
  // YritysWIFI is implemented as a separate add-on that is automatically
  // included in the price once.
  const yritysWifiDeliveryFeeAddOnProductName = 'Wifi Mesh-palvelun toimitus';

  return findAddOn(
    addOnCodes.yritysWifiDeliveryFeeAddOnCode,
    yritysWifiDeliveryFeeAddOnProductName,
    commercialProductCode,
    commercialProductAddOnVisibilities
  );
};

export const findYritysWifiInstallationServiceAddOn = (
  commercialProductCode?: string,
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap
) => {
  // YritysWIFI installation service is used only when the Netti Lite
  // installation service is not wanted and the YritysWIFI add-on is selected.
  const yritysWifiInstallationServiceAddOnProductName = 'Wifi Mesh-palvelun asennus';

  return findAddOn(
    addOnCodes.yritysWifiInstallationServiceAddOnCode,
    yritysWifiInstallationServiceAddOnProductName,
    commercialProductCode,
    commercialProductAddOnVisibilities
  );
};

export const findComboInstallationServiceAddOn = (
  commercialProductCode?: string,
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap
) => {
  // There is an installation service that includes both Netti Lite installation
  // and YritysWIFI installation service combined. It can be included only if
  // the main add-on is selected.
  const yritysWifiInstallationServiceAddOnProductName = 'Asennuspalvelu';

  return findAddOn(
    addOnCodes.comboInstallationServiceAddOnCode,
    yritysWifiInstallationServiceAddOnProductName,
    commercialProductCode,
    commercialProductAddOnVisibilities
  );
};
