import { Catalog } from '../../generated/api/catalog.js';
import {
  getCatalogModificationStatus,
  getCatalogStatus,
  getCatalogToBeDisplayed,
} from '../../common/utils/catalogUtils.js';
import { monthMsg, t } from '../../common/i18n/index.js';
import type { CheckboxTreeItem } from '../CheckboxTree/ChecboxTree.js';
import ProductTypeEnum = Catalog.ProductTypeEnum;
import type { CatalogHeader } from '../../generated/api/catalogHeader.js';
import type { VirtualCatalogHeader } from '../../generated/api/virtualCatalogHeader.js';

export const LOCATION_TYPE_REPLACE = 'replace';

export interface CompanyVirtualCatalogs {
  companyName: string;
  virtualCatalogs: VirtualCatalogHeader[];
}

const getTreeItem = (
  catalogAndStatus: {
    catalog?: CatalogHeader;
    status: string;
  },
  productType: Catalog.ProductTypeEnum
): CheckboxTreeItem => {
  const status = catalogAndStatus.status ? `, ${catalogAndStatus.status}` : '';
  const infoText =
    productType === Catalog.ProductTypeEnum.EPP_RECURRING
      ? `${catalogAndStatus.catalog?.contractPeriod} ${t.XXVX(monthMsg)}`
      : `${t.QYSL('One-time fee')}`;
  const childLabel = (
    <>
      {catalogAndStatus.catalog?.name}&nbsp;
      <i>
        ({infoText}
        {status})
      </i>
    </>
  );
  return {
    label: childLabel,
    value: catalogAndStatus.catalog?.catalogCode || '',
  };
};

const getTreeItemChildren = (
  companyCatalogs: CompanyVirtualCatalogs,
  productType: Catalog.ProductTypeEnum
): CheckboxTreeItem[] => {
  return (
    companyCatalogs.virtualCatalogs
      ?.map(virtualCatalog => {
        const catalogToBeDisplayed = getCatalogToBeDisplayed(virtualCatalog);
        const status = getCatalogModificationStatus(getCatalogStatus(virtualCatalog)).text;
        return {
          catalog: catalogToBeDisplayed,
          status: status,
        };
      })
      .filter(
        catalogAndStatus =>
          catalogAndStatus.catalog?.productType === productType &&
          catalogAndStatus.catalog.productType !== Catalog.ProductTypeEnum.RECURRING
      )
      .map(catalogAndStatus => {
        return getTreeItem(catalogAndStatus, productType);
      }) || []
  );
};

export const getTreeItems = (
  companyVirtualCatalogs: CompanyVirtualCatalogs[],
  productType: ProductTypeEnum
): CheckboxTreeItem[] => {
  return companyVirtualCatalogs
    .map(companyCatalogs => {
      return {
        label: companyCatalogs.companyName,
        value: companyCatalogs.companyName,
        children: getTreeItemChildren(companyCatalogs, productType),
      };
    })
    .filter(treeItem => treeItem.children.length > 0);
};
