import { Category } from '../../common/utils/cartProductUtils.js';
import { CommercialProductType } from '../../generated/api/models.js';
import { SELECTED_COMMERCIAL_PRODUCT_INDEX } from '../../common/constants/commonConstants.js';
import { TypeKeys } from '../actions/index.js';
import { VAT_AS_DECIMAL } from '../../common/utils/taxUtils.js';
import { centsToEuros, getMonthlyPrice } from '../../common/utils/priceUtils.js';
import { isCampaignPresent } from '../../common/utils/campaignUtils.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import type { Action } from 'redux';
import type { AddedAddon } from '../../common/types/addon.js';
import type { CampaignAssociation, CommercialProduct } from '../../generated/api/models.js';
import type { ConfiguredCommercialProduct, ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { EcommerceItem } from '../../common/analytics.js';
import type { GoogleEcommerceActionTypes } from '../actions/index.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';

export const DEFAULT_ITEM_BRAND = 'Elisa';
export const DEFAULT_PAYMENT_TYPE = 'invoice';

export enum PaymentTypeEnum {
  INVOICE = 'invoice',
  CREDIT_CARD = 'credit card',
}

const GOOGLE_ENHANCED_ECOMMERCE_ACTIONS = [
  TypeKeys.GOOGLE_ECOMMERCE_ADD_PAYMENT_INFO,
  TypeKeys.GOOGLE_ECOMMERCE_ADD_SHIPPING_INFO,
  TypeKeys.GOOGLE_ECOMMERCE_ADD_TO_CART,
  TypeKeys.GOOGLE_ECOMMERCE_EVENT,
  TypeKeys.GOOGLE_ECOMMERCE_CHECKOUT,
  TypeKeys.GOOGLE_ECOMMERCE_IMPRESSIONS_PUSHED,
  TypeKeys.GOOGLE_ECOMMERCE_PRODUCT_CLICK,
  TypeKeys.GOOGLE_ECOMMERCE_PRODUCT_DETAIL,
  TypeKeys.GOOGLE_ECOMMERCE_PURCHASE,
  TypeKeys.GOOGLE_ECOMMERCE_REMOVE_FROM_CART,
];

export const isGoogleEcommerceAction = (action: Action): action is GoogleEcommerceActionTypes =>
  isInBrowser() && GOOGLE_ENHANCED_ECOMMERCE_ACTIONS.indexOf(action.type) > -1;

export const priceToString = (priceInCents: number) => centsToEuros(priceInCents).toFixed(2);

const calculateAddonOneTimeRevenue = (addedAddOns?: AddedAddon[]) => {
  return (addedAddOns || []).reduce((sum: number, addon) => sum + (addon.oneTimePrice ?? 0), 0);
};

const calculateAddonMonthlyRevenue = (addedAddOns?: AddedAddon[]) => {
  return (addedAddOns || []).reduce((sum: number, addon) => sum + (addon.monthlyPrice ?? 0), 0);
};

export const getProductCampaignAssociation = ({ campaignAssociations }: CommercialProduct) => {
  if (campaignAssociations?.[0] && isCampaignPresent(campaignAssociations)) {
    return campaignAssociations[0];
  }
  return undefined;
};

const calculateFirstYearAddOnRevenue = (addOns: AddedAddon[]) => {
  const addOnMonthlies = calculateAddonMonthlyRevenue(addOns);
  const addOnOneTimes = calculateAddonOneTimeRevenue(addOns);

  return addOnOneTimes + 12 * addOnMonthlies;
};

export const calculateProductRevenue = (
  cp: CommercialProduct,
  addedAddOns?: AddedAddon[],
  ca?: CampaignAssociation
) => {
  const pca = ca ?? getProductCampaignAssociation(cp);

  const addOnMonthlies = calculateAddonMonthlyRevenue(addedAddOns);
  const addOnOneTimes = calculateAddonOneTimeRevenue(addedAddOns);

  const monthlyRecurringCharge = cp.monthlyRecurringCharge
    ? getMonthlyPrice(cp.monthlyRecurringCharge - (pca?.monthlyRecurringCharge ?? 0), cp.billingPeriod) || 0
    : 0;

  const oneTimeCharge = cp.oneTimeCharge ? cp.oneTimeCharge - (pca?.oneTimeCharge ?? 0) : 0;

  // Subscriptions' payments are perpetual (0), so we calculate a year's worth
  const monthlyRecurringCharges = (monthlyRecurringCharge + addOnMonthlies) * (cp.payments || 12);

  const oneTimeCharges = oneTimeCharge + addOnOneTimes;

  return monthlyRecurringCharges + oneTimeCharges;
};

export const getProductPriceData = (cos: ConfiguredOffer[]) => {
  const totalShipping = 0;
  let totalRevenue = 0;
  const itemInfo = cos.reduce((acc, curr) => {
    const co = curr.offer;
    const ca = curr.selectedCampaignAssociation;
    curr.selectedCommercialProducts.forEach((ccp: ConfiguredCommercialProduct) => {
      const cp = ccp.commercialProduct;
      const revenue = calculateProductRevenue(ccp.commercialProduct, ccp.addedAddOns, ca);
      acc.push({
        ...(curr?.voucher ? { coupon: curr.voucher } : {}),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ...(ccp.manufacturer ? { item_brand: ccp.manufacturer } : {}),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        item_category: ccp.category ?? cp.productSubType ?? cp.productType,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        item_id: curr.onlineModelCode ?? co.offerCode,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        item_name: curr.onlineModelName ?? co.offerName,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        item_variant: cp.commercialProductName,
        price: centsToEuros(revenue),
        quantity: 1,
      });
      totalRevenue += revenue;
    });
    return acc;
  }, [] as EcommerceItem[]);

  return {
    totalRevenue,
    totalShipping,
    totalTax: totalRevenue * VAT_AS_DECIMAL,
    itemInfo,
  };
};

const createEcommerceEventProduct = (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_id: string,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_category: string,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_name: string,
  onetimeChargeInCents: number,
  recurringChargeInCents: number,
  recurrences: number,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_brand?: string,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_variant?: string,
  quantity?: number,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_list_name?: string,
  index?: number // position in list
): EcommerceItem => {
  // This is the price _per item_, not the total.
  const price = centsToEuros(onetimeChargeInCents + recurringChargeInCents * recurrences);
  return {
    ...(index ? { index } : {}),
    item_category, // eslint-disable-line @typescript-eslint/naming-convention
    ...(item_brand ? { item_brand } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    item_id, // eslint-disable-line @typescript-eslint/naming-convention
    ...(item_list_name ? { item_list_name } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    item_name, // eslint-disable-line @typescript-eslint/naming-convention
    item_variant, // eslint-disable-line @typescript-eslint/naming-convention
    price,
    quantity,
  };
};

export const createEcommerceItemFromCommercialProducts = (
  id: string,
  category: string | undefined,
  name: string,
  variant: string,
  commercialProducts: CommercialProduct[],
  quantity: number,
  brand?: string
): EcommerceItem => {
  return createEcommerceEventProduct(
    id,
    category ?? commercialProducts[0].productSubType ?? commercialProducts[0].productType,
    name,
    commercialProducts.reduce((sum, cp) => sum + (cp.oneTimeCharge || 0), 0),
    commercialProducts.reduce(
      (sum, cp) => sum + (getMonthlyPrice(cp.monthlyRecurringCharge, cp.billingPeriod) || 0),
      0
    ),
    // Subscriptions' payments are perpetual (0), so we calculate a year's worth
    commercialProducts[0].payments || 12,
    brand,
    variant,
    quantity
  );
};

const getShoppingCartItemManufacturer = (shoppingCartItem: ShoppingCartItemForCheckout) => {
  const { category, manufacturer, onlineModelName } = shoppingCartItem;
  if (
    (manufacturer == null || manufacturer === 'N/A') &&
    category === Category.SALES_PRODUCT &&
    (onlineModelName.startsWith('Elisa') || onlineModelName.startsWith('Yritysdata 5G'))
  ) {
    return 'Elisa';
  }
  return manufacturer;
};

// FIXME wrong category for sales products
export const createEcommerceItemFromShoppingCartItem = (
  shoppingCartItem: ShoppingCartItemForCheckout,
  quantity?: number
): EcommerceItem => {
  const manufacturer = getShoppingCartItemManufacturer(shoppingCartItem);
  return createEcommerceEventProduct(
    shoppingCartItem.onlineModelCode ?? shoppingCartItem.offerCode,
    shoppingCartItem.category,
    shoppingCartItem.onlineModelName ?? shoppingCartItem.productName,
    shoppingCartItem.price.onetime?.price ?? 0,
    getMonthlyPrice(shoppingCartItem.price.periodic?.price, shoppingCartItem.price.periodic?.billingPeriod) ?? 0,
    // Subscriptions' payments are perpetual (0), so we calculate a year's worth
    shoppingCartItem.price.periodic?.payments || 12,
    manufacturer,
    shoppingCartItem.productName,
    quantity ?? shoppingCartItem.quantity
  );
};

const NETTI_LITE_LIST_NAME = 'Nettilite saatavuus';

export const createNettiliteEcommerceItemFromConfiguredProduct = (
  cp: CommercialProduct,
  voucher?: string,
  addedAddOns?: AddedAddon[],
  ca?: CampaignAssociation
): EcommerceItem => ({
  coupon: voucher,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_brand: DEFAULT_ITEM_BRAND,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_category: cp.productType,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_id: cp.commercialProductCode,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_list_name: NETTI_LITE_LIST_NAME,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_name: cp.commercialProductName,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_variant: cp.commercialProductName,
  price: centsToEuros(calculateProductRevenue(cp, addedAddOns, ca)),
  quantity: 1,
});

export const createNettiliteEcommerceItemFromAddedAddon = (addedAddOn: AddedAddon): EcommerceItem => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_brand: DEFAULT_ITEM_BRAND,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_category: CommercialProductType.FIXED_BROADBAND,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_id: addedAddOn.addOnCode,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_list_name: NETTI_LITE_LIST_NAME,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_name: addedAddOn.displayName,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_variant: addedAddOn?.addOnAttributes?.displayNameOverride ?? addedAddOn.displayName,
  price: centsToEuros(calculateFirstYearAddOnRevenue([addedAddOn])),
  quantity: 1,
});

export const createNettiliteEcommerceItemsFromConfiguredOffer = (configuredOffer: ConfiguredOffer) => {
  const eCommerceEventProducts = configuredOffer.selectedCommercialProducts.map(scp =>
    createNettiliteEcommerceItemFromConfiguredProduct(scp.commercialProduct, configuredOffer.voucher)
  );

  const addedAddOns = configuredOffer.selectedCommercialProducts[SELECTED_COMMERCIAL_PRODUCT_INDEX].addedAddOns || [];

  // Addon quantity may be larger than one, in such cases duplicates should be
  // removed but the first year revenue should still be calculated correctly.
  const uniqueAddOns = addedAddOns.reduce(
    (acc: { [p: string]: AddedAddon }, addOn: AddedAddon): { [p: string]: AddedAddon } => {
      if (acc[addOn.addOnCode]) {
        if (acc[addOn.addOnCode].monthlyPrice) {
          acc[addOn.addOnCode].monthlyPrice = (acc[addOn.addOnCode].monthlyPrice ?? 0) + (addOn?.monthlyPrice ?? 0);
        }
      } else {
        acc[addOn.addOnCode] = JSON.parse(JSON.stringify(addOn)); // Copy to avoid mutating original's monthlyPrice
        acc[addOn.addOnCode].displayName =
          acc[addOn.addOnCode]?.addOnAttributes?.displayNameOverride ?? acc[addOn.addOnCode].displayName;
      }
      return acc;
    },
    {}
  );

  return [
    ...eCommerceEventProducts,
    ...Object.values(uniqueAddOns).map(addedAddon => createNettiliteEcommerceItemFromAddedAddon(addedAddon)),
  ];
};
