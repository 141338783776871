import { EppMaintenanceAdmin } from '../../../../../components/EppMaintenance/EppMaintenanceAdmin.js';
import { useLoaderData } from 'react-router-dom';
import type { DeviceSupportRequestLoaderResponse } from '../../../../../common/loaders.js';

export const DeviceSupportRequestPath = () => {
  const { companyInfo, pendingSubscriptionActions, subscription } =
    useLoaderData() as DeviceSupportRequestLoaderResponse;

  return (
    <EppMaintenanceAdmin
      subscription={subscription}
      companyInfo={companyInfo}
      pendingSubscriptionActions={pendingSubscriptionActions}
    />
  );
};
