export const isDefined = <T>(e: T | undefined | null): e is T => e !== undefined && e !== null;
export const isNotDefined = (e: unknown): boolean => !isDefined(e);
export const isObject = (e: unknown): e is object => e !== null && typeof e === 'object';
export const isNumber = (e: unknown): e is number => typeof e === 'number';
export const isString = (e: unknown): e is string => typeof e === 'string';

// Both object and arrays with their keys/contents in different order are considered equal.
// Note: Some arrays, even if they have same contents, but different order, may not be considered equal.
//       See the `Arrays of same objects, in different order equal` test in `objectUtils.test.ts` for example of issue.
export function deepEqual<T>(object1: T, object2: T): boolean {
  if (isObject(object1) && isObject(object2)) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key as keyof T];
      const val2 = object2[key as keyof T];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }
    return true;
  } else {
    return object1 === object2;
  }
}

export const isFieldNotEmpty = (obj: object, key: string) => {
  if (Array.isArray(obj[key as keyof object])) {
    // @ts-ignore
    return obj[key] !== undefined && obj[key] !== null && obj[key].length > 0;
  }
  // @ts-ignore
  return obj[key] !== undefined && obj[key] !== null && obj[key] !== '';
};

export const isObjectNotEmpty = (obj: object, ...keys: string[]) => {
  if (keys && keys.length > 0) {
    return keys.some(key => isFieldNotEmpty(obj, key));
  }
  return Object.keys(obj).some(key => isFieldNotEmpty(obj, key));
};

export const isObjectEmpty = (obj: object, ...keys: string[]) => {
  return !isObjectNotEmpty(obj, ...keys);
};

export const isNullOrUndefined = (value: unknown) => value === null || value === undefined;

export const isEmptyOrNullOrUndefined = (value?: object | null): boolean => {
  if (value === undefined || value === null) {
    return true;
  }
  if (typeof value !== 'object') {
    return false;
  }
  return Object.keys(value).length === 0 && value.constructor === Object;
};

export function mergeObjects<T extends object>(defaults: T, updates: object) {
  const filteredUpdates = Object.entries(updates)
    .filter(([_, value]) => Boolean(value))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  return { ...defaults, ...filteredUpdates };
}

const mergeArrays = <T>(array1: T[], array2: T[]) => {
  return Array.from(new Set([...array1, ...array2]));
};

export const deepMerge = <T>(target: T, source: Partial<T>) => {
  const merged = { ...target };

  if (!source) {
    return target;
  }

  for (const [key, value] of Object.entries(source)) {
    if (value !== undefined) {
      if (isObject(value)) {
        if (Array.isArray(value)) {
          const existingArray = merged[key as keyof T];
          merged[key as keyof T] = (existingArray ? mergeArrays(existingArray as never[], value) : value) as T[keyof T];
        } else {
          const existingObject = merged[key as keyof T];
          merged[key as keyof T] = (existingObject ? deepMerge(existingObject, value) : value) as T[keyof T];
        }
      } else {
        merged[key as keyof T] = value as T[keyof T];
      }
    }
  }

  return merged;
};

export const findString = <T extends object>(obj: T, searchString: string): string[] => {
  if (searchString) {
    const results = Object.entries(obj).map(([k, v]) => {
      if (isObject(v)) {
        return findString(v, searchString)
          .map(e => (e ? `${k}.${e}` : ''))
          .filter(Boolean) // Get rid of non-matches (empty strings).
          .join(', ');
      } else {
        return v?.toString().toLowerCase().includes(searchString.toLowerCase()) ? k : '';
      }
    });
    return results.filter(Boolean); // Get rid of non-matches (empty strings).
  } else {
    return [];
  }
};

export type SearchResult<T> = {
  result: T;
  matchedFields?: string[];
};

export const searchArray = <T extends object>(obj: T[], searchString?: string): SearchResult<T>[] => {
  if (searchString) {
    return obj
      .map(e => ({ result: e, matchedFields: findString(e, searchString) }))
      .filter(e => e.matchedFields.length > 0);
  } else {
    return obj.map(e => ({ result: e, matchedFields: [] }));
  }
};
