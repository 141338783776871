import { FixedBroadbandProductSelection } from '../../FixedBroadbandProductSelection/FixedBroadbandProductSelection.js';
import { createNettiliteEcommerceItemsFromConfiguredOffer } from '../../../selfservice/common/googleEcommerceEvent.js';
import { getCampaignOptions, getConfiguredOffer } from '../FixedBroadbandUtils.js';
import { googleEcommerceProductDetail } from '../../../selfservice/actions/index.js';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import type { AddOnVisibility } from '../../../generated/api/addOnVisibility.js';
import type { AddedAddon } from '../../../common/types/addon.js';
import type { CampaignAssociation } from '../../../generated/api/campaignAssociation.js';
import type { CampaignContext } from '../../../generated/api/campaignContext.js';
import type { CampaignsState } from '../../../common/types/states.js';
import type {
  CheckoutStepProps,
  InstallationServiceAddOnSelection,
  YritystuplaOfferSelection,
} from '../FixedBroadbandCheckout.js';
import type { CommercialProductAddOnVisibilitiesMap } from '../../../common/utils/addOnVisibilityUtils.js';
import type { ConfiguredOffer } from '../../../common/types/commercialProduct.js';
import type { IpAddressAddOnSelection } from '../../FixedBroadbandProductSelection/FixedBroadbandIpAddOn.js';
import type { Offer } from '../../../generated/api/offer.js';
import type { OnlineModel } from '../../../generated/api/onlineModel.js';
import type { YritysWifiSelection } from '../../FixedBroadbandProductSelection/FixedBroadbandYritysWifiAddOn.js';

interface StepProductSelectionProps extends CheckoutStepProps {
  selectedOffer: Offer;
  yritystuplaModel?: OnlineModel;
  yritystuplaOffer: YritystuplaOfferSelection;
  setYritystuplaOffer: React.Dispatch<React.SetStateAction<YritystuplaOfferSelection>>;
  yritysWifiSelection: YritysWifiSelection;
  setYritysWifiSelection: (yritysWifiSelection: YritysWifiSelection) => void;
  configuredOffer: ConfiguredOffer;
  campaignContextsFromVoucher: CampaignContext[];
  campaigns?: CampaignsState | null;
  selectedCampaign?: CampaignAssociation;
  installationServiceSelection: InstallationServiceAddOnSelection;
  setInstallationServiceSelection: React.Dispatch<React.SetStateAction<InstallationServiceAddOnSelection>>;
  ipAddressAddOnSelection: IpAddressAddOnSelection;
  setIpAddressAddOnSelection: React.Dispatch<React.SetStateAction<IpAddressAddOnSelection>>;
  setGFastDeviceIncluded: React.Dispatch<React.SetStateAction<boolean>>;
  setCampaignAutoSelect: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCampaign: React.Dispatch<React.SetStateAction<CampaignAssociation | undefined>>;
  fixedBroadbandOnlineModel: OnlineModel;
  selectedAddOns: AddedAddon[];
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap;
  onAddOnSelectionChange: (addedAddOns: AddedAddon[]) => void;
  publicAddOnVisibilities?: AddOnVisibility[];
}

export const StepProductSelection = (props: StepProductSelectionProps) => {
  const dispatch = useDispatch();

  const {
    selectedOffer,
    yritystuplaModel,
    yritystuplaOffer,
    setYritystuplaOffer,
    yritysWifiSelection,
    setYritysWifiSelection,
    configuredOffer,
    campaignContextsFromVoucher,
    campaigns,
    selectedCampaign,
    installationServiceSelection,
    setInstallationServiceSelection,
    ipAddressAddOnSelection,
    setIpAddressAddOnSelection,
    setGFastDeviceIncluded,
    setCampaignAutoSelect,
    enterNextStep,
    setSelectedCampaign,
    fixedBroadbandOnlineModel,
    selectedAddOns,
    commercialProductAddOnVisibilities,
    onAddOnSelectionChange,
    publicAddOnVisibilities,
  } = props;

  const campaignVoucher = campaigns?.voucher;
  const campaignVoucherPromotions = useMemo(() => campaigns?.campaigns || [], [campaigns?.campaigns]);

  const commercialProduct = selectedOffer.commercialProducts[0];

  const campaignOptions = getCampaignOptions(
    commercialProduct,
    setSelectedCampaign,
    campaignContextsFromVoucher,
    selectedCampaign,
    campaignVoucher
  );

  const eCommerceProductDetailEvent = useCallback(() => {
    const offer = getConfiguredOffer(
      selectedOffer,
      commercialProduct,
      fixedBroadbandOnlineModel.campaigns || [],
      campaignVoucherPromotions,
      selectedAddOns,
      yritystuplaOffer,
      selectedCampaign,
      campaignVoucher
    );
    const product = createNettiliteEcommerceItemsFromConfiguredOffer(offer);
    dispatch(googleEcommerceProductDetail(product));
  }, [
    selectedOffer,
    commercialProduct,
    fixedBroadbandOnlineModel.campaigns,
    campaignVoucherPromotions,
    selectedAddOns,
    yritystuplaOffer,
    selectedCampaign,
    campaignVoucher,
    dispatch,
  ]);

  return (
    <FixedBroadbandProductSelection
      eCommerceProductDetailEvent={eCommerceProductDetailEvent}
      selectedOffer={selectedOffer}
      yritystuplaModel={yritystuplaModel}
      yritystuplaOffer={yritystuplaOffer}
      setYritystuplaOffer={setYritystuplaOffer}
      yritysWifiSelection={yritysWifiSelection}
      setYritysWifiSelection={setYritysWifiSelection}
      configuredOffer={configuredOffer}
      campaignContextsFromVoucher={campaignContextsFromVoucher}
      campaignVoucher={campaignVoucher}
      campaignOptions={campaignOptions}
      selectedCampaign={selectedCampaign}
      installationServiceSelection={installationServiceSelection}
      setInstallationServiceSelection={setInstallationServiceSelection}
      ipAddressAddOnSelection={ipAddressAddOnSelection}
      setIpAddressAddOnSelection={setIpAddressAddOnSelection}
      setGFastDeviceIncluded={setGFastDeviceIncluded}
      setCampaingAutoSelect={setCampaignAutoSelect}
      onNext={() => enterNextStep(1)}
      commercialProductAddOnVisibilities={commercialProductAddOnVisibilities}
      onAddOnSelectionChange={onAddOnSelectionChange}
      publicAddOnVisibilities={publicAddOnVisibilities}
    />
  );
};
