import * as CL from '@design-system/component-library';
import { AddOnExternalLink } from '../SubscriptionAdditionalService/AddOnExternalLink.js';
import { ToggleCheckbox } from '../ToggleCheckbox/ToggleCheckbox.js';
import { formatSum, formatSumToString } from '../../common/utils/priceUtils.js';
import { startingAtMsg, sumPerMonthMsg, t } from '../../common/i18n/index.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { ChangeEvent } from 'react';

import './FixedBroadbandYritysWifiAddOn.scss';

export interface YritysWifiSelection {
  enabled: boolean;
  quantity: number;
  selectedAddOn?: AddedAddon;
  deliveryFeeAddOn?: AddedAddon;
}

export interface FixedBroadbandYritysWifiAddOnProps {
  yritysWifiAddOn: AddedAddon;
  yritysWifiDeliveryFeeAddOn: AddedAddon;
  yritysWifiAddOnSelection: YritysWifiSelection;
  onChange: (selection: YritysWifiSelection) => void;
}

const YritysWifiOfferOptions = ({ monthlyPrice, oneTimePrice }: { monthlyPrice: number; oneTimePrice?: number }) => {
  const hasOneTimeCharge = !!oneTimePrice;

  const oneTimeCharge = hasOneTimeCharge ? (
    <div className="ds-text--xs">
      {t.WZ46('Delivery fee')} <span>{formatSum(oneTimePrice ?? 0)}</span>
    </div>
  ) : undefined;

  return (
    <>
      <CL.Radio className="of-fixed-bb-yrityswifi-addon__radio" key={1} value="1">
        <div className="ds-display--flex ds-justify-content--space-between of-fixed-bb-yrityswifi-addon__radio-label">
          <div>
            <div>80 m² - 120 m² (1 {t.P3SF('MESH device')})</div>
            {oneTimeCharge}
          </div>
          <div className="ds-padding-left--2">{t.YO7F(sumPerMonthMsg, formatSumToString(monthlyPrice))}</div>
        </div>
      </CL.Radio>
      <CL.Radio className="of-fixed-bb-yrityswifi-addon__radio" key={2} value="2">
        <div className="ds-display--flex ds-justify-content--space-between of-fixed-bb-yrityswifi-addon__radio-label">
          <div>
            <div>120 m² - 160 m² ({t.Q5UU('2 MESH devices', '2')})</div>
            {oneTimeCharge}
          </div>
          <div className="ds-padding-left--2">{t.YO7F(sumPerMonthMsg, formatSumToString(2 * monthlyPrice))}</div>
        </div>
      </CL.Radio>
      <CL.Radio className="of-fixed-bb-yrityswifi-addon__radio" key={3} value="3">
        <div className="ds-display--flex ds-justify-content--space-between of-fixed-bb-yrityswifi-addon__radio-label">
          <div>
            <div>
              {t.JX0H('Over')} 160 m² ({t.Q5UU('3 MESH devices', '3')})
            </div>
            {oneTimeCharge}
          </div>
          <div className="ds-padding-left--2">{t.YO7F(sumPerMonthMsg, formatSumToString(3 * monthlyPrice))}</div>
        </div>
      </CL.Radio>
    </>
  );
};

const getStartingAtPriceInfo = (addedAddon: AddedAddon) => {
  const minMonthlyPrice = addedAddon.monthlyPrice ?? 0;
  const sumPerMonth = formatSum(minMonthlyPrice) ?? '';

  return { value: `${t.OD1L(startingAtMsg)} ${t.YO7F(sumPerMonthMsg, sumPerMonth)}` };
};

const YritysWifiAdditionalInfo = () => (
  <>
    <div className="of-fixed-bb-yrityswifi-addon__additional-info">
      {t.WT3N(
        'Elisa YritysWIFI Lite provides better, faster internet everywhere in your office premises with an easy monthly subscription.'
      )}
    </div>
    <AddOnExternalLink linkHref="https://yrityksille.elisa.fi/ohjeet/lisapalvelut" />
  </>
);

export const FixedBroadbandYritysWifiAddOn = ({
  yritysWifiAddOn,
  yritysWifiAddOnSelection,
  yritysWifiDeliveryFeeAddOn,
  onChange,
}: FixedBroadbandYritysWifiAddOnProps) => {
  const onToggleChange = (checked: boolean) => {
    onChange({
      ...yritysWifiAddOnSelection,
      deliveryFeeAddOn: yritysWifiDeliveryFeeAddOn,
      enabled: checked,
    });
  };

  const onOfferChange = (e: ChangeEvent<HTMLInputElement>) => {
    const quantity = e.target.value;

    if (quantity) {
      onChange({
        ...yritysWifiAddOnSelection,
        quantity: Number(quantity),
      });
    }
  };

  const priceInfo = getStartingAtPriceInfo(yritysWifiAddOn);
  const selectedQuantity = yritysWifiAddOnSelection?.quantity ?? 1;

  if (yritysWifiAddOn.monthlyPrice === undefined) {
    return null;
  }

  return (
    <ToggleCheckbox
      title={t.A8DZ('Elisa YritysWIFI Lite – a broader WIFI network for your office.')}
      additionalInformation={<YritysWifiAdditionalInfo />}
      onChange={onToggleChange}
      priceInfo={priceInfo}
      initialValue={yritysWifiAddOnSelection.enabled}
    >
      <div className="of-fixed-bb-yrityswifi-addon__price">
        <div className="ds-text--bold ds-padding-top--3">{t.LDYA('Choose the size of your office premises:')}</div>
        <CL.Radio.Group onChange={onOfferChange} value={selectedQuantity.toString()}>
          <YritysWifiOfferOptions
            monthlyPrice={yritysWifiAddOn.monthlyPrice}
            oneTimePrice={yritysWifiDeliveryFeeAddOn.oneTimePrice}
          />
        </CL.Radio.Group>
      </div>
    </ToggleCheckbox>
  );
};
