export const findBestMatchForExtensionNumber = (phoneNumber: string, extensions: string[] = []) => {
  if (extensions.length === 0) {
    return '';
  }

  const lastThreeDigits = phoneNumber.slice(-3);
  const lastTwoDigits = phoneNumber.slice(-2);

  return (
    extensions.find(num => num.toString().slice(-3) === lastThreeDigits) ||
    extensions.find(num => num.toString().slice(-2) === lastTwoDigits) ||
    ''
  );
};
