import { AutoComplete } from '../../../components/AutoComplete/AutoComplete.js';
import { CREATE_NEW_CONTACT_OPTION_VALUE, getContactOptions } from '../../formik/Fields/index.js';
import { ContactType } from '../../../generated/api/contactType.js';
import { fetchDuplicateContactCheck } from '../../fetch.js';
import { fieldCantBeEmptyMsg, noSelectionMsg, t } from '../../i18n/index.js';
import { getTextError } from '../utils.js';
import { useController, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import type * as CLT from '@design-system/component-library';
import type { Contact as ContactInterface } from '../../../generated/api/contact.js';
import type { ContactPerson } from '../../../generated/api/contactPerson.js';
import type { DuplicateContact } from '../../../generated/api/duplicateContact.js';

export interface ContactAsyncConfig {
  contactConcatenatedKey: string;
  contactKey: string;
  setPrompt: (vals: ContactAndDuplicateContactsProps) => void;
}

interface ContactProps {
  className?: string;
  contacts: Array<ContactInterface>;
  name: string;
  contactAsyncConfig?: ContactAsyncConfig;
}

export interface ContactAndDuplicateContactsProps {
  contact: ContactInterface;
  duplicateContacts: DuplicateContact[];
}

export const createConcatenatedString = (person: ContactPerson): string => Object.values(person).join('');

type FormValues = Record<string, string | ContactPerson>;

export const Contact = (props: ContactProps) => {
  const validate = async (val: string, vals: FormValues) => {
    const asyncConfig = props.contactAsyncConfig!;
    const person = vals[asyncConfig.contactKey] as ContactPerson;
    const contact = { person, contactType: ContactType.PERSON };
    const contactConcatenated = vals[asyncConfig.contactConcatenatedKey];

    // Existing contact is selected and there's no need for duplicate check
    if (val !== CREATE_NEW_CONTACT_OPTION_VALUE) {
      return true;
    }

    // Verify that ContactPerson has all the valid fields
    if (!person || Object.values(person || {}).some((v?: string) => !v)) {
      return false;
    }
    // Give free pass if new contact has been added through the process and last concatenated string is same
    if (contactConcatenated && contactConcatenated === createConcatenatedString(person)) {
      return true;
    }

    const contactsDuplicateCheck = { contacts: [{ identifier: '', contact: contact }] };
    const { duplicateContacts } = await fetchDuplicateContactCheck(contactsDuplicateCheck);
    if (duplicateContacts?.length) {
      asyncConfig.setPrompt({ contact, duplicateContacts });
    }
    return duplicateContacts?.length === 0;
  };

  const name = props.name;
  const contacts = getContactOptions(props.contacts, true);
  const methods = useFormContext();
  const { control, setValue, setError } = methods;
  const values = methods.watch();
  const reset = values.key || 0;
  const rules = {
    required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) },
    validate: props.contactAsyncConfig ? validate : undefined,
  };
  const { field, fieldState, formState } = useController({ name, control, rules });
  const defaultValues = formState.defaultValues;
  const keys = name.split('.');
  const value = keys.reduce((current, key) => (current ? current[key] : undefined), defaultValues);
  const defaultValue = contacts.find(i => (reset === 0 ? i.value === value : i.value === field.value));
  const error = getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error);

  return (
    <AutoComplete<CLT.SearchItemProps>
      className={classNames('large-min-height', props.className)}
      inputRef={field.ref}
      options={contacts}
      defaultOption={defaultValue}
      getUniqueId={i => i.value}
      getDisplayHtml={i => i.html}
      getDisplayValue={i => i.label}
      onInputBlur={field.onBlur}
      onInputChange={(e, item) => {
        setValue(name, item.value);
        setError(name, { message: undefined });
      }}
      id={`${name}-autocomplete`}
      noOptionsMsg={t.ZW5W(noSelectionMsg)}
      label={t.VYZS('Contact person')}
      error={error}
      config={{ isSortable: false }}
      // If key changes = AutoComplete resets (unmount and mount)
      // This is the correct way to update value from the parent component
      key={reset}
    />
  );
};
