import * as CL from '@design-system/component-library';
import { getCatalogModificationStatus } from '../../common/utils/catalogUtils.js';
import type { VirtualCatalogStatus } from '../../generated/api/virtualCatalogStatus.js';

import './ModificationStatus.scss';

interface ModificationStatusProps {
  status: VirtualCatalogStatus;
  noSubtext?: boolean;
}

export const ModificationStatus = (props: ModificationStatusProps) => {
  const { status, noSubtext } = props;
  const { color, text, subtext } = getCatalogModificationStatus(status);

  return (
    <div className="of-modification-status ds-display--flex ds-align-items--center">
      <CL.Badge type="status" color={color} className={`of-modification-status-badge-${color}`} />
      <div>
        <div>{text}</div>
        {!noSubtext && <div className="ds-font-size--smaller">{subtext}</div>}
      </div>
    </div>
  );
};
