import { DnsDomainList } from './DnsDomainList/DnsDomainList.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { isFeatureEnabledForUser } from '../../../common/utils/featureFlagUtils.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { State } from '../../../selfservice/common/store.js';
import type { SubscriptionsResponse } from '../../../generated/api/subscriptionsResponse.js';

export const DnsDomainManagement = () => {
  const { searchResults, total } = useLoaderData() as SubscriptionsResponse;
  const { authenticatedUser } = useAuth();
  const config = useSelector((state: State) => state.config, deepEqual);

  if (!isFeatureEnabledForUser('dnsManagement', config.featureFlags, authenticatedUser?.enabledFeatureFlags)) {
    return null;
  }

  return <DnsDomainList searchResults={searchResults} total={total} authenticatedUser={authenticatedUser} />;
};
