import { ContactDropdown } from '../../common/react-hook-form/fields/ContactDropdown.js';
import { EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '../Loading/index.js';
import {
  PersonalBillingDetails,
  createPersonBillingDetailsData,
} from '../PersonalBillingDetails/PersonalBillingDetails.js';
import { RedeemOptionDetails } from './EppRedeemOptionDetails.js';
import { WizardActions } from '../WizardActions/index.js';
import { approverContactMsg, cancelMsg, redeemIsBinding, t } from '../../common/i18n/index.js';
import { findPersonBillingAccount } from '../../common/utils/stateUtils.js';
import { loadBillingAccounts, loadContacts } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEppRedeemForwardClick } from './EppRedeemUtils.js';
import { useLocation } from 'react-router-dom';
import type { AuthenticatedUserState, BillingAccountsState } from '../../common/types/states.js';
import type { Contact, Subscription } from '../../generated/api/models.js';
import type { EmployeeSubscription } from '../../common/types/subscription.js';

import './EppRedeem.scss';

export interface EppRedeemEmployeeProps {
  billingAccounts?: BillingAccountsState | null;
  companyName?: string;
  contacts?: Contact[];
  email?: string;
  firstName?: string;
  lastName?: string;
  loggedInUserContactId?: string;
  mobile?: string;
  onBackClick: () => void;
  subscription: EmployeeSubscription | Subscription;
}

const getApproverOptions = (contacts: Contact[] = [], contactIdToFilterOut?: string): Contact[] =>
  contactIdToFilterOut ? contacts.filter(item => item.contactId !== contactIdToFilterOut) : contacts;

export const EppRedeemEmployee = ({
  billingAccounts,
  companyName,
  contacts,
  email,
  firstName,
  lastName,
  loggedInUserContactId,
  mobile,
  onBackClick,
  subscription,
}: EppRedeemEmployeeProps) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [approverContact, setApproverContact] = useState('');
  const [billingAccountId, setBillingAccountId] = useState(subscription.billingAccountId);
  const [billingAccountDisplayId, setBillingAccountDisplayId] = useState(subscription.billingAccountDisplayId);
  const personBillingAccount = findPersonBillingAccount(billingAccounts || undefined);
  const personBillingDetailsData = createPersonBillingDetailsData(
    {
      firstName,
      lastName,
      email,
      mobile,
    } as AuthenticatedUserState,
    personBillingAccount
  );
  const methods = useForm({ values: personBillingDetailsData });
  const personalBillingDetails = methods.watch();

  const forwardClick = useEppRedeemForwardClick({
    billingAccountId,
    approverContact,
    subscription,
    companyName,
    personBillingAccount,
    isRedeemedByAdmin: false,
    redeemType: EppRedeemTerminateRequestType.EMPLOYEE_REDEEM,
    personalBillingDetails,
    firstName,
    lastName,
  });

  useEffect(() => {
    if (!billingAccounts?.items) {
      dispatch(loadBillingAccounts());
    }
    if (!contacts) {
      dispatch(loadContacts());
    }
  }, [billingAccounts?.items, contacts, dispatch]);

  useEffect(() => {
    const baId = location.state?.updatedBillingAccount?.billingAccountId;
    const baDisplayId = location.state?.updatedBillingAccount?.billingAccountDisplayId;
    if (baId && baDisplayId) {
      setBillingAccountId(baId);
      setBillingAccountDisplayId(baDisplayId);
    }
  }, [location.state]);

  return billingAccounts?.items && contacts ? (
    <div className="of-epp-redeem">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(forwardClick)}>
          <div className="ds-padding-top--2 ds-padding-bottom--5">
            {`${t.CW9X(
              'Redemption requires the approval of your employer. Redemption request is automatically sent to your employer. After approval, the invoice will be sent to you via email.'
            )} ${t.UNYV(redeemIsBinding)}`}
          </div>
          <RedeemOptionDetails
            billingAccountId={billingAccountId}
            billingAccountDisplayId={billingAccountDisplayId}
            subscription={subscription}
            selectedOption={EppRedeemTerminateRequestType.EMPLOYEE_REDEEM}
            companyName={companyName}
          />
          <>
            <ContactDropdown
              canAddNewContacts={false}
              className="ds-margin--0"
              name="approverContact"
              labelText={t.GOZ7(approverContactMsg)}
              contacts={getApproverOptions(contacts, loggedInUserContactId)}
              onChangeContact={setApproverContact}
            />
            <PersonalBillingDetails editing={personBillingAccount === undefined} data={personBillingDetailsData} />
          </>
          <hr className="ds-margin-vertical--4" />
          <WizardActions
            forwardButtonText={t.I62A('Continue')}
            backButtonText={t.B2V1(cancelMsg)}
            onForwardClick={() => undefined}
            onBackClick={onBackClick}
          />
        </form>
      </FormProvider>
    </div>
  ) : (
    <Loading />
  );
};
