import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { Picture } from '../Picture/Picture.js';
import { ShoppingCartAddon } from './ShoppingCartAddon.js';
import {
  addMsg,
  additionalServicesMsg,
  paymentPeriodMsg,
  quantityMsg,
  removeMsg,
  subtractMsg,
  t,
} from '../../common/i18n/index.js';
import { getAddonsDisplayData } from '../../selfservice/common/shopping-cart/shoppingCartFunctions.js';
import { useCallback } from 'react';
import type { PriceDisplayData } from './shoppingCartUtils.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { ShoppingCartPaymentOption } from '@design-system/component-library';

const getProductImage = (productName: string, imageUrl: string): JSX.Element => {
  return (
    <Picture alt={productName} offerWidthAlternatives={[128]} renderedImageSize={{ onPhone: '64px' }} src={imageUrl} />
  );
};

export interface ShoppingCartEntryProps {
  cartItem: ShoppingCartItemForCheckout;
  productUrl: string;
  priceDisplayData: PriceDisplayData;
  paymentOptions: ShoppingCartPaymentOption[];
  disclaimerFields: string[];
  onPaymentOptionChange: (productId: string, paymentOptionId: string) => void;
  onQuantityChange: (productId: string, quantity: number) => void;
}

/**
 * This component is doing closely the same as in Design System (v. 9.1.0)
 * file ShoppingCart.tsx --> function ShoppingCartEntry(). At least the dom structure and style classes should be same.
 * That's how this is looking same than DS version.
 *
 * This is because new Shopping Basket needs to customise ShoppingCartEntries and addons that DS Shopping cart shows.
 * Originated from here: https://atlas.elisa.fi/jira/browse/OFI-53793
 * TODO: these modifications could be contributed to DS side at some point
 */

export const ShoppingCartEntry = ({
  cartItem,
  productUrl,
  priceDisplayData,
  paymentOptions,
  disclaimerFields,
  onPaymentOptionChange,
  onQuantityChange,
}: ShoppingCartEntryProps) => {
  const onPaymentOptionValueChange = useCallback(
    (paymentOption: HTMLLIElement) => onPaymentOptionChange(cartItem.id, paymentOption.dataset.value!),
    [cartItem.id, onPaymentOptionChange]
  );

  const paymentOptionsElement =
    paymentOptions.length > 1 ? (
      <CL.Dropdown
        className="ds-shoppingcart__product-price-options"
        items={paymentOptions.map(option => ({
          label: option.label,
          value: option.id,
        }))}
        label={t.EM2Q(paymentPeriodMsg)}
        onValueChange={onPaymentOptionValueChange}
        optional={false}
        selectedValue={paymentOptions.find(option => option.selected)?.id}
      />
    ) : (
      <div className="ds-shoppingcart__product-price-options" />
    );

  return (
    <li key={cartItem.id} className="ds-shoppingcart__product">
      <div className="ds-shoppingcart__product-info">
        <div aria-hidden="true" className="ds-shoppingcart__product-image">
          {getProductImage(cartItem.productName, cartItem.imageListingUrl)}
        </div>
        <div className="ds-shoppingcart__product-details">
          <div className="ds-shoppingcart__product-name-and-description">
            <h3 className="ds-shoppingcart__product-name">
              <Link to={productUrl}>{cartItem.productName}</Link>
            </h3>
            <div className="ds-shoppingcart__product-description" />
          </div>
          {paymentOptionsElement}
          <div className="ds-shoppingcart__product-quantity">
            <CL.Quantity
              i18n_quantity_deleteAriaLabel={t.R3VE(removeMsg)}
              i18n_quantity_minusAriaLabel={t.C2KQ(subtractMsg)}
              i18n_quantity_plusAriaLabel={t.VKFM(addMsg)}
              i18n_quantity_quantityAriaLabel={t.M0W7(quantityMsg)}
              className="ds-shoppingcart__product-quantity"
              currentValue={cartItem.quantity}
              minValue={1}
              onChange={(quantity: number) => {
                // Comment copied from DS (v. 9.1.0):
                // remove causes an extra onChange with the previous quantity (i.e. 1), this avoids firing a change on those
                // we can't just use onChange as the change quantity is minValue on remove...
                // and if we don't use removeLessThanMin, we don't get the trashcan icon
                if (quantity !== cartItem.quantity) {
                  onQuantityChange(cartItem.id, quantity);
                }
              }}
              onRemove={() => {
                onQuantityChange(cartItem.id, 0);
              }}
              removeLessThanMin
            />
          </div>
          <div className="ds-shoppingcart__product-price-and-disclaimer">
            <div className="ds-shoppingcart__product-price">
              <output className="ds-shoppingcart__price-amount">{priceDisplayData.shoppingCartPrice.amount}</output>
              <span className="ds-shoppingcart__price-unit">{priceDisplayData.shoppingCartPrice.unit}</span>
            </div>
            <div className="ds-shoppingcart__product-disclaimer">
              {disclaimerFields.map((row, rowIndex) => (
                <div key={rowIndex}>{row}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ul aria-label={t.LXSR(additionalServicesMsg)} className="ds-shoppingcart__addons">
        <ShoppingCartAddon addOns={getAddonsDisplayData(cartItem.selectedAddOns, cartItem.quantity)} />
      </ul>
    </li>
  );
};
