import * as CL from '@design-system/component-library';
import { Anchor } from '../Anchor/Anchor.js';
import { InProgressCatalogAction } from '../../common/enums.js';
import { VirtualCatalogStatus } from '../../generated/api/models.js';
import {
  createdMsg,
  deleteChangesMsg,
  modifiedMsg,
  publishMsg,
  publishedMsg,
  t,
  viewThePublishedVersionMsg,
} from '../../common/i18n/index.js';
import { deleteVirtualCatalogDraft, publishVirtualCatalogDraft } from '../../selfservice/actions/index.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCatalogStatus } from '../../common/utils/catalogUtils.js';
import { useDispatch } from 'react-redux';
import type { Catalog, VirtualCatalog } from '../../generated/api/models.js';

import './VirtualCatalogDraftOptions.scss';

export interface VirtualCatalogDraftOptionsProps {
  catalog: Catalog;
  virtualCatalog: VirtualCatalog;
  viewPublished: boolean;
  setViewPublished: (viewPublished: boolean) => void;
  inProgressAction?: InProgressCatalogAction;
}

const PublishCatalogForbiddenMessage = () => {
  return (
    <>
      {t.T95T('Enable')} <i>{t.KE7V(publishMsg)}</i> {t.MMA5('button by adding the correct billing agreement.')}{' '}
    </>
  );
};

export const VirtualCatalogDraftOptions = ({
  catalog,
  virtualCatalog,
  viewPublished,
  setViewPublished,
  inProgressAction,
}: VirtualCatalogDraftOptionsProps): JSX.Element => {
  const dispatch = useDispatch();
  const virtualCatalogStatus = getCatalogStatus(virtualCatalog);
  const isPublishingCatalogForbidden = !virtualCatalog.draft?.billingAccountId;

  if (virtualCatalogStatus === VirtualCatalogStatus.MODIFIED) {
    return (
      <div className="ea-context-notification of-manage-catalog-draft">
        <div className="ea-context-notification__text">
          <div className="of-manage-catalog-draft__container">
            <div className="of-manage-catalog-draft__instructions">
              {t.RUFL(modifiedMsg)} {formatTimestampToDDMMYYYY(virtualCatalog.draft?.lastModified)} <br />
              {t.JN25(publishedMsg)} {formatTimestampToDDMMYYYY(virtualCatalog.published?.lastModified)}{' '}
              {isPublishingCatalogForbidden && <PublishCatalogForbiddenMessage />}
              <div>
                <Anchor
                  onClick={() => {
                    setViewPublished(!viewPublished);
                  }}
                >
                  {t.QNC2(viewThePublishedVersionMsg)}
                </Anchor>
              </div>
            </div>
            <div className="of-manage-catalog-draft__actions">
              <CL.Button
                className="of-manage-catalog-draft__button__delete"
                color="light"
                loading={inProgressAction === InProgressCatalogAction.DELETE_VIRTUAL_CATALOG_DRAFT}
                onClick={() => {
                  dispatch(deleteVirtualCatalogDraft(virtualCatalog.virtualCatalogCode, catalog.catalogCode ?? ''));
                }}
                size="l"
              >
                {t.OPH9(deleteChangesMsg)}
              </CL.Button>
              <CL.Button
                className="of-manage-catalog-draft__button__publish"
                loading={inProgressAction === InProgressCatalogAction.PUBLISH_VIRTUAL_CATALOG_DRAFT}
                disabled={isPublishingCatalogForbidden}
                onClick={() => {
                  dispatch(publishVirtualCatalogDraft(virtualCatalog.virtualCatalogCode));
                }}
                size="l"
              >
                {t.KE7V(publishMsg)}
              </CL.Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (virtualCatalogStatus === VirtualCatalogStatus.DRAFT) {
    return (
      <div className="ea-context-notification of-manage-catalog-draft">
        <div className="ea-context-notification__text">
          <div className="of-manage-catalog-draft__container">
            <div className="of-manage-catalog-draft__instructions">
              {t.V549('Device list is not published. The list will only be available after publication.')}{' '}
              {isPublishingCatalogForbidden && <PublishCatalogForbiddenMessage />}
              {t.GSYE(createdMsg)} {formatTimestampToDDMMYYYY(virtualCatalog.draft?.created)} <br />
            </div>
            <div className="of-manage-catalog-draft__actions">
              <CL.Button
                className="of-manage-catalog-draft__button__publish"
                disabled={isPublishingCatalogForbidden}
                onClick={() => {
                  dispatch(publishVirtualCatalogDraft(virtualCatalog.virtualCatalogCode));
                }}
              >
                {' '}
                {t.KE7V(publishMsg)}{' '}
              </CL.Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};
