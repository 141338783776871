import { Link, generatePath, useLocation } from 'react-router-dom';
import { WizardType } from '../../common/enums.js';
import { changeMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { Subscription } from '../../generated/api/models.js';

type BillingAccountSelectionProps = {
  billingAccountDisplayId: string;
  billingAccountId: string;
  subscription: Subscription;
};

export const BillingAccountSelection = ({
  billingAccountDisplayId,
  billingAccountId,
  subscription,
}: BillingAccountSelectionProps): JSX.Element => {
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const billingAccountPath = generatePath(paths.BILLING_ACCOUNT, { billingAccountId: billingAccountDisplayId });
  return (
    <span>
      <Link to={`${billingAccountPath}?${queryParams}`}>{billingAccountDisplayId}</Link>
      <span>
        <span className="of-vertical-bar"> | </span>
        <Link
          to={`${pathname}?${queryParams}`}
          state={{
            subscription,
            billingAccountDisplayId,
            billingAccountId,
            type: WizardType.CHANGE_EPP_REDEEM_BILLING_ACCOUNT,
          }}
        >
          {t.SEER(changeMsg)}
        </Link>
      </span>
    </span>
  );
};
