import { EppSupportOrServiceAnnouncement } from './EppSupportOrServiceAnnouncement.js';
import { EppSupportOrServiceRequestForm } from './EppSupportOrServiceRequestForm.js';
import { useState } from 'react';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { Subscription } from '../../generated/api/models.js';

interface EppSupportOrServiceRequestProps {
  isEmployee: boolean;
  onCancel: () => void;
  subscription: Subscription;
  companyInfo?: CompanyInfoState | null;
}

export const EppSupportOrServiceRequest = ({
  isEmployee,
  onCancel,
  subscription,
  companyInfo,
}: EppSupportOrServiceRequestProps) => {
  const [announcementConfirmed, setAnnouncementConfirmed] = useState(false);

  const onNext = () => setAnnouncementConfirmed(true);

  return announcementConfirmed ? (
    <EppSupportOrServiceRequestForm
      isEmployee={isEmployee}
      onCancel={onCancel}
      subscription={subscription}
      companyInfo={companyInfo}
    />
  ) : (
    <EppSupportOrServiceAnnouncement isEmployee={isEmployee} onCancel={onCancel} onNext={onNext} />
  );
};
