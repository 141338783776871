import * as CL from '@design-system/component-library';
import { ToggleCheckbox } from '../ToggleCheckbox/ToggleCheckbox.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { startingAtMsg, t } from '../../common/i18n/index.js';
import type { AddOn, CampaignContext } from '../../generated/api/models.js';
import type { AddedAddon } from '../../common/types/addon.js';

import './FixedBroadbandInstallationService.scss';

export interface FixedBroadbandInstallationAddOnOptions {
  nettiLiteInstallationServiceAddOn?: AddedAddon;
  yritysWifiInstallationServiceAddOn?: AddedAddon;
  comboInstallationServiceAddOn?: AddedAddon;
}

interface FixedBroadbandInstallationServiceProps {
  addOnOptions: FixedBroadbandInstallationAddOnOptions;
  enableYritysWifiInstallationOptions: boolean;
  selectedInstallationAddOn?: AddedAddon;
  installationAddOnChanged: (addOn: AddedAddon) => void;
  numberOfMeshDevices?: number;
  onChange: (installationServiceSelected: boolean) => void;
  campaignContextsFromVoucher?: CampaignContext;
  campaignVoucher?: string;
  showRecommendedDisclaimer?: boolean;
}

const getPriceInfo = (addOns: AddOn[], campaignContextsFromVoucher?: CampaignContext) => {
  const cheapestAddOn = addOns.sort(
    (left, right) => (left.price?.effectivePrice.oneTimeCharge ?? 0) - (right.price?.effectivePrice.oneTimeCharge ?? 0)
  )[0];

  const price = cheapestAddOn.price?.listPrice.oneTimeCharge || 0;
  const voucherPrice = campaignContextsFromVoucher?.campaignAssociations[0].oneTimeCharge;
  const discountedPrice = cheapestAddOn.price?.effectivePrice.oneTimeCharge;

  return discountedPrice && discountedPrice < price
    ? {
        value: `${t.OD1L(startingAtMsg)} ${formatSum(voucherPrice ? discountedPrice - voucherPrice : discountedPrice)}`,
        additionalText: formatSum(price),
      }
    : {
        value: `${t.OD1L(startingAtMsg)} ${formatSum(voucherPrice ? price - voucherPrice : price)}`,
        additionalText: voucherPrice ? formatSum(price) : undefined,
      };
};

export const FixedBroadbandInstallationService = ({
  addOnOptions,
  enableYritysWifiInstallationOptions,
  selectedInstallationAddOn,
  installationAddOnChanged,
  numberOfMeshDevices = 0,
  onChange,
  campaignVoucher,
  campaignContextsFromVoucher,
  showRecommendedDisclaimer,
}: FixedBroadbandInstallationServiceProps) => {
  const basicInfo = t.P4CJ('Start using your broadband with ease.');
  const additionalInformation = showRecommendedDisclaimer
    ? `${basicInfo} ${t.V996(
        'Please note that if the connection is ordered elsewhere than home, the installation service is recommended.'
      )}`
    : basicInfo;

  const onAddOnSelected = (addOnCode: string) => {
    installationAddOnChanged(Object.values(addOnOptions).find(a => a.addOnCode === addOnCode));
  };

  const selectedAddOnCode = enableYritysWifiInstallationOptions
    ? (selectedInstallationAddOn?.addOnCode ?? addOnOptions.nettiLiteInstallationServiceAddOn?.addOnCode)
    : addOnOptions.nettiLiteInstallationServiceAddOn?.addOnCode;

  return (
    <div className="of-fixed-bb-installation-service">
      <div className="of-fixed-bb-installation-service__content">
        <ToggleCheckbox
          title={t.LUCJ('Installation service')}
          additionalInformation={additionalInformation}
          onChange={onChange}
          badge={
            campaignVoucher && campaignContextsFromVoucher
              ? {
                  text: campaignVoucher,
                  color: 'warning',
                  type: 'text',
                }
              : undefined
          }
          priceInfo={getPriceInfo(Object.values(addOnOptions), campaignContextsFromVoucher)}
        >
          <div className="ds-text--bold ds-padding-top--3">{t.Y0IR('Select installation service')}</div>
          <div className="of-fixed-bb-installation__price">
            <CL.Radio.Group
              key={selectedAddOnCode}
              onChange={e => onAddOnSelected(e.currentTarget.value)}
              value={selectedAddOnCode}
            >
              <CL.Radio
                className="of-fixed-bb-installation__radio"
                value={addOnOptions.nettiLiteInstallationServiceAddOn?.addOnCode}
              >
                <div className="ds-display--flex ds-justify-content--space-between of-fixed-bb-installation__radio-label">
                  <div>
                    <div>{t.UXSJ('Internet subscription installation')}</div>
                    <div className="ds-text--xs">
                      {t.TEVT(
                        'The service includes the installation of the modem and connection and functionality testing.'
                      )}
                    </div>
                  </div>
                  <div className="ds-padding-left--2 of-fixed-bb-installation__option-price">
                    {formatSum(addOnOptions.nettiLiteInstallationServiceAddOn?.oneTimePrice)}
                  </div>
                </div>
              </CL.Radio>

              {addOnOptions.yritysWifiInstallationServiceAddOn && (
                <CL.Radio
                  className="of-fixed-bb-installation__radio"
                  value={addOnOptions.yritysWifiInstallationServiceAddOn?.addOnCode}
                  disabled={!enableYritysWifiInstallationOptions}
                >
                  <div className="ds-display--flex ds-justify-content--space-between of-fixed-bb-installation__radio-label">
                    <div>
                      <div>{t.V7HT('Wireless Mesh network installation')}</div>
                      <div className="ds-text--xs">
                        {t.DS9O(
                          'The service includes the installation of the WIFI Mesh devices ({}) optimally positioned so that the WIFI network covers the entire home/office',
                          numberOfMeshDevices?.toString()
                        )}
                      </div>
                    </div>
                    <div className="ds-padding-left--2 of-fixed-bb-installation__option-price">
                      {formatSum(addOnOptions.yritysWifiInstallationServiceAddOn?.oneTimePrice)}
                    </div>
                  </div>
                </CL.Radio>
              )}

              {addOnOptions.comboInstallationServiceAddOn && (
                <CL.Radio
                  className="of-fixed-bb-installation__radio"
                  value={addOnOptions.comboInstallationServiceAddOn?.addOnCode}
                  disabled={!enableYritysWifiInstallationOptions}
                >
                  <div className="ds-display--flex ds-justify-content--space-between of-fixed-bb-installation__radio-label">
                    <div>
                      <div>{t.E2HY('Internet subscription and wireless Mesh network installation')}</div>
                      <div className="ds-text--xs">
                        {t.ZGX7(
                          'The service includes the installation of the modem and connection, as well as the installation of the WIFI Mesh devices ({}) optimally positioned so that the WIFI network covers the entire home/office. The service also includes the functionality testing of the network.',
                          numberOfMeshDevices?.toString()
                        )}
                      </div>
                    </div>
                    <div className="ds-padding-left--2 of-fixed-bb-installation__option-price">
                      {formatSum(addOnOptions.comboInstallationServiceAddOn?.oneTimePrice)}
                    </div>
                  </div>
                </CL.Radio>
              )}
            </CL.Radio.Group>
          </div>
        </ToggleCheckbox>
      </div>
    </div>
  );
};
