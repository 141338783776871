import { CommercialProductType } from '../generated/api/commercialProductType.js';
import { CustomerOrderListFields, SubscriptionCategory } from './enums.js';
import { CustomerOrderStatus } from '../generated/api/customerOrderStatus.js';
import { OnlineModelCategory } from '../generated/api/onlineModelCategory.js';
import { SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM } from '../components/Subscriptions/SubscriptionsVoice.js';
import { SourceSystem } from '../generated/api/sourceSystem.js';
import { SubscriptionType } from '../generated/api/subscriptionType.js';
import { TableSortOrder, TableUrlParams, getItemsPerPageFromOptionsOrDefault } from '../components/Table/index.js';
import { createDnsRecordsLoader } from '../components/DnsManagement/dnsManagementUtils.js';
import { defer } from 'react-router-dom';
import {
  fetchBillChannels,
  fetchBillingAccount,
  fetchBillingAccounts,
  fetchCatalogOnlineModelHeaders,
  fetchCompanyInfo,
  fetchContactFromES,
  fetchContacts,
  fetchCustomerOrder,
  fetchCustomerOrderAdditionalInfo,
  fetchCustomerOrders,
  fetchDnsRecordHistory,
  fetchDnsRecords,
  fetchDnsRecordsHistory,
  fetchInvoice,
  fetchInvoiceDocuments,
  fetchInvoices,
  fetchOnlineModelHeaders,
  fetchOnlineModels,
  fetchOpenSupportCases,
  fetchSubscription,
  fetchSubscriptionAction,
  fetchSubscriptionActions,
  fetchSubscriptionAggregates,
  fetchSubscriptions,
  fetchSupportCase,
  fetchSupportCaseHistory,
  fetchSupportCases,
  fetchVirtualCatalogs,
  getMessagesFromChatHistory,
  validate,
} from './fetch.js';
import { getAiChatSessionId, getShoppingCart } from '../selfservice/common/localStorageUtils.js';
import { getContactSort } from './utils/contactUtils.js';
import { getSubscriptionTypes } from '../public/common/util/category.js';
import { mergeObjects } from './utils/objectUtils.js';
import { replacePipeWithCommaInQueryParams } from './utils/filterUtils.js';
import { resolveSort } from './utils/supportCaseUtils.js';
import type { BillChannel } from '../generated/api/billChannel.js';
import type { BillingAccount } from '../generated/api/billingAccount.js';
import type { BillingAccountHeader } from '../generated/api/billingAccountHeader.js';
import type { BillingAccountSearchResponse } from '../generated/api/billingAccountSearchResponse.js';
import type { BillingAccountsResponse } from '../generated/api/billingAccountsResponse.js';
import type { CartItemType } from '../selfservice/common/shopping-cart/shoppingCartInterfaces.js';
import type { CompanyInfoResponse } from '../generated/api/companyInfoResponse.js';
import type { CompanyInfoState } from './types/states.js';
import type { Contact } from '../generated/api/contact.js';
import type { ContactsResponse } from '../generated/api/contactsResponse.js';
import type { CustomerOrder } from '../generated/api/customerOrder.js';
import type { CustomerOrderAdditionalInfo } from '../generated/api/customerOrderAdditionalInfo.js';
import type { DefaultListSearchParams } from '../components/Table/index.js';
import type { Invoice } from '../generated/api/invoice.js';
import type { InvoiceDocumentsResponse } from 'generated/api/invoiceDocumentsResponse.js';
import type { InvoicesResponse } from 'generated/api/invoicesResponse.js';
import type { LoaderFunctionArgs } from 'react-router-dom';
import type { OnlineModelsResponse } from '../generated/api/onlineModelsResponse.js';
import type { Subscription } from '../generated/api/subscription.js';
import type { SubscriptionAction } from '../generated/api/subscriptionAction.js';
import type { SubscriptionActionsResponse } from '../generated/api/subscriptionActionsResponse.js';
import type { SubscriptionAggregationsResponse } from '../generated/api/subscriptionAggregationsResponse.js';
import type { SubscriptionSearchResponse } from '../generated/api/subscriptionSearchResponse.js';
import type { SubscriptionsResponse } from '../generated/api/subscriptionsResponse.js';
import type { SupportCaseDataBundle } from '../generated/api/supportCaseDataBundle.js';
import type { SupportCaseHeader } from '../generated/api/supportCaseHeader.js';
import type { SupportCaseHistory } from '../generated/api/supportCaseHistory.js';
import type { SupportCasesResponse } from 'generated/api/supportCasesResponse.js';
import type { SupportCasesSearchResponse } from '../generated/api/supportCasesSearchResponse.js';

export interface SupportCaseLoaderResponse {
  supportCase: SupportCaseDataBundle;
  history: SupportCaseHistory[];
}

export interface BillingAccountLoaderResponse {
  billingAccount: BillingAccount;
  billChannels: BillChannel[];
  contacts: Contact[];
}

export interface InvoiceLoaderResponse {
  invoice: Invoice;
  billChannels: BillChannel[];
  openSupportCases: SupportCaseHeader[];
  billingAccount?: BillingAccount;
}

export interface DeviceSubscriptionLoaderResponse {
  billingAccounts: BillingAccountHeader[];
  companyInfo: CompanyInfoState;
  contacts: Contact[];
  pendingSubscriptionActions: SubscriptionAction[];
  subscription: Subscription;
}

export type DeviceRedemptionLoaderResponse = Pick<
  DeviceSubscriptionLoaderResponse,
  'companyInfo' | 'pendingSubscriptionActions' | 'subscription'
>;

export type DeviceSupportRequestLoaderResponse = DeviceRedemptionLoaderResponse;

export type DeviceAddonLoaderResponse = Pick<
  DeviceSubscriptionLoaderResponse,
  'pendingSubscriptionActions' | 'subscription'
>;

const defaultRequest = {
  offset: 0,
  order: 'desc',
};

type LoaderDefaultParams = DefaultListSearchParams & {
  params: URLSearchParams;
  itemsPerPage?: number;
};

const getCompanyIdFromRequest = (request: Request): string | undefined =>
  new URL(request.url).searchParams.get('companyId') || undefined;

export const getSearchParams = (request: Request): LoaderDefaultParams => {
  const params = new URLSearchParams(new URL(request.url).search);
  const itemsPerPage = getItemsPerPageFromOptionsOrDefault(params.get('limit') || undefined);
  const offset = params.get('offset') || '0';
  const order = params.get('order') || undefined;
  const search = params.get('search') || undefined;
  const sort = params.get('sort') || undefined;
  return { itemsPerPage, offset, order, search, sort, params };
};

export const supportCaseLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<SupportCaseLoaderResponse> => {
  if (params.supportCaseDisplayId === undefined) {
    throw new Error('missing supportCaseDisplayId');
  }
  const companyId = getCompanyIdFromRequest(request);
  return Promise.all([
    fetchSupportCase(params.supportCaseDisplayId, companyId),
    fetchSupportCaseHistory(params.supportCaseDisplayId, companyId),
  ]).then(fetchResponse => ({ supportCase: fetchResponse[0], history: fetchResponse[1].caseHistory || [] }));
};

export const getSupportCases = ({ request }: LoaderFunctionArgs) => {
  const { params, sort, offset, itemsPerPage, ...rest } = getSearchParams(request);
  return fetchSupportCases(
    mergeObjects(defaultRequest, {
      feature: params.get('feature'),
      status: params.get('status'),
      offset: Number(offset),
      limit: itemsPerPage,
      sort: resolveSort(sort),
      ...rest,
    })
  );
};

export const billingAccountLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<BillingAccountLoaderResponse> => {
  if (params.billingAccountId === undefined) {
    throw new Error('missing billingAccountId');
  }
  const companyId = getCompanyIdFromRequest(request);
  const [billingAccounts, billChannels, contactsResponse] = await Promise.all([
    fetchBillingAccount(params.billingAccountId, companyId),
    fetchBillChannels(),
    fetchContacts({ offset: 0 }, companyId),
  ]);
  const billingAccount = billingAccounts.billingAccounts?.[0] as BillingAccount;
  if (!billingAccount) {
    throw new Response('Billing account not found', { status: 404 });
  }
  return {
    billingAccount,
    billChannels,
    contacts: contactsResponse.contacts || [],
  };
};

export interface BillingAccountListLoaderData {
  billingAccounts?: BillingAccountsResponse;
}

/**
 * Get billing accounts from Elasticsearch so that they can be shown in the
 * billing account list.
 *
 * For the billing account list billing accounts from all source systems (SFDC,
 * MIPA, TELLUS) should be shown without restrictions. Restrictions may apply
 * to billing accounts shown in dropdowns when they are selected for orders
 * and such.
 */
export const billingAccountListLoader = async ({ request }: LoaderFunctionArgs) => {
  const { itemsPerPage, offset, search, sort } = getSearchParams(request);
  return {
    billingAccounts: await fetchBillingAccounts({
      limit: itemsPerPage,
      offset: Number(offset),
      search,
      sort,
      useSearchService: true,
    }),
  };
};

/**
 * Loader for Catalog Configurations including billing-accounts, bill-channels and contacts
 * billing-accounts and bill-contacts calls are fast, so they're required from the start
 * contacts are slow, so the data is referred and used later in the application for optimal UX
 */
export const ccLoader = async () => {
  return defer({
    billingAccounts: await fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }),
    billChannels: await fetchBillChannels(),
    contacts: fetchContacts({ offset: 0 }),
  });
};

export interface BaLoaderData {
  billChannels: BillChannel[];
  contacts: ContactsResponse;
  companyInfo: CompanyInfoState;
}

export const baLoader = async ({ request }: LoaderFunctionArgs): Promise<BaLoaderData> => {
  const companyId = getCompanyIdFromRequest(request);
  return {
    billChannels: await fetchBillChannels(),
    contacts: await fetchContacts({ offset: 0 }, companyId),
    companyInfo: await fetchCompanyInfo(companyId),
  };
};

export const customerOrdersLoader = ({ request }: LoaderFunctionArgs) => {
  const { params, sort, offset, itemsPerPage, ...rest } = getSearchParams(request);
  return fetchCustomerOrders(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      offset: Number(offset),
      limit: itemsPerPage,
      status: params.get('status') || undefined,
      sort: sort || CustomerOrderListFields.CREATED,
      ...rest,
    })
  );
};

export interface CustomerOrderLoaderData {
  customerOrder: CustomerOrder;
  billingAccounts: BillingAccountsResponse;
  additionalInfo?: CustomerOrderAdditionalInfo;
  companyInfo?: CompanyInfoResponse;
  contacts?: ContactsResponse;
}

export const customerOrderLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<CustomerOrderLoaderData> => {
  if (params.orderId === undefined) {
    throw new Error('missing customerOrderId');
  }
  const companyId = new URL(request.url).searchParams.get('companyId') || undefined;
  const customerOrder = (await fetchCustomerOrder(params.orderId, companyId)).customerOrders?.[0] as CustomerOrder;
  if (!customerOrder) {
    throw new Response('Customer order not found', { status: 404 });
  }
  const pending = customerOrder.status === CustomerOrderStatus.PENDING_APPROVAL;
  return {
    customerOrder,
    additionalInfo: pending ? await fetchCustomerOrderAdditionalInfo(params.orderId) : undefined,
    companyInfo: pending ? await fetchCompanyInfo(companyId) : undefined,
    billingAccounts: await fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, companyId),
    contacts: await fetchContacts({ useSearchService: true, offset: 0 }),
  };
};

export const dnsRecordHistoryLoader = ({ params }: LoaderFunctionArgs) =>
  fetchDnsRecordHistory(params.subscriptionId!, Number(params.historyId!));

export const dnsRecordsHistoryLoader = createDnsRecordsLoader(fetchDnsRecordsHistory, {
  [TableUrlParams.SORT]: 'id',
  [TableUrlParams.ORDER]: TableSortOrder.DESC,
  [TableUrlParams.LIMIT]: '30',
});

export const getDnsRecords = createDnsRecordsLoader(fetchDnsRecords, {
  [TableUrlParams.SORT]: 'type',
  [TableUrlParams.ORDER]: TableSortOrder.ASC,
  [TableUrlParams.LIMIT]: '30',
});

export const subscriptionsLoader = (
  category: SubscriptionCategory,
  { request }: LoaderFunctionArgs,
  contactId?: string
) => {
  const { params, offset, itemsPerPage, ...rest } = getSearchParams(request);
  const mdmId = getCompanyIdFromRequest(request);
  const subscriptionType =
    (params.get(SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM) as SubscriptionCategory | null) ||
    getSubscriptionTypes(category);
  const subscriptionSubType = params.get('subscriptionSubType');
  const subscriptionContactId = contactId;
  return fetchSubscriptions(
    mergeObjects(defaultRequest, {
      details: true,
      offset: Number(offset),
      limit: itemsPerPage,
      subscriptionType,
      subscriptionSubType,
      subscriptionContactId,
      useSearchService: true,
      ...replacePipeWithCommaInQueryParams(Object.fromEntries(params), ['limit']),
      ...rest,
    }),
    mdmId
  );
};

export const voiceSubscriptionsLoader = (args: LoaderFunctionArgs) => {
  return subscriptionsLoader(SubscriptionCategory.VOICE, args);
};

export const broadbandSubscriptionsLoader = (args: LoaderFunctionArgs) => {
  return subscriptionsLoader(SubscriptionCategory.BROADBAND, args);
};

export interface DeviceSubscriptionsLoaderData {
  subscriptions: SubscriptionsResponse;
  aggregations: SubscriptionAggregationsResponse;
}

export interface ContactSubscriptionsLoaderData {
  device: SubscriptionSearchResponse[];
  voice: SubscriptionSearchResponse[];
  broadband: SubscriptionSearchResponse[];
  service: SubscriptionSearchResponse[];
  billingAccounts: BillingAccountSearchResponse[];
}

export interface OnlineModelLoaderData {
  phones: OnlineModelsResponse;
  accessories: OnlineModelsResponse;
  tablets: OnlineModelsResponse;
  computers: OnlineModelsResponse;
  networkEquipment: OnlineModelsResponse;
}

export const contactSubscriptionsLoader = async (args: LoaderFunctionArgs) => {
  // contactId needed, get that from the contact
  const contactMasterId = args.params.contactMasterId;
  const mdmId = getCompanyIdFromRequest(args.request);
  const res = await fetchContactFromES({
    contactMasterId: contactMasterId!,
    mdmId,
  });
  const searchResults = res.searchResults || [];
  if (searchResults.length !== 1) {
    throw new Error('Contact not found');
  }
  const contactId = searchResults[0].result.contactId;

  // And now fetch the subscriptions for the contact using contactId
  const [deviceResponse, voiceResponse, broadbandResponse, serviceResponse, billingAccountResponse] = await Promise.all(
    [
      subscriptionsLoader(SubscriptionCategory.DEVICE, args, contactId),
      subscriptionsLoader(SubscriptionCategory.VOICE, args, contactId),
      subscriptionsLoader(SubscriptionCategory.BROADBAND, args, contactId),
      subscriptionsLoader(SubscriptionCategory.SERVICE, args, contactId),
      fetchBillingAccounts(
        { billingContactId: contactId, useSearchService: true, sourceSystem: SourceSystem.SFDC },
        mdmId
      ),
    ]
  );
  return {
    device: deviceResponse.searchResults,
    voice: voiceResponse.searchResults,
    broadband: broadbandResponse.searchResults,
    service: serviceResponse.searchResults,
    billingAccounts: billingAccountResponse.searchResults,
  };
};

export const deviceSubscriptionsLoader = async (args: LoaderFunctionArgs) => {
  return {
    subscriptions: await subscriptionsLoader(SubscriptionCategory.DEVICE, args),
    aggregations: await fetchSubscriptionAggregates(SubscriptionType.DEVICE, args),
  };
};

export const subscriptionLoader = async (
  subscriptionTypes: string[],
  subscriptionId?: string,
  companyId?: string
): Promise<Subscription> => {
  if (!subscriptionId) {
    throw new Error('missing subscriptionId');
  }
  const subscriptionsResponse = await fetchSubscription(subscriptionId, subscriptionTypes, companyId);
  const subscription = subscriptionsResponse?.subscriptions?.[0];
  if (!subscription) {
    throw new Response('Subscription not found', { status: 404 });
  }
  return subscription;
};

export const pendingSubscriptionActionsLoader = async (companyId?: string) => {
  const loginResponse = await validate(companyId);
  return loginResponse.result?.pendingSubscriptionActions || [];
};

export const deviceRedemptionLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<DeviceRedemptionLoaderResponse> => {
  const companyId = getCompanyIdFromRequest(request);
  const [companyInfo, pendingSubscriptionActions, subscription] = await Promise.all([
    fetchCompanyInfo(companyId),
    pendingSubscriptionActionsLoader(companyId),
    subscriptionLoader([SubscriptionType.DEVICE], params.subscriptionId, companyId),
  ]);
  return { companyInfo, pendingSubscriptionActions, subscription };
};

export const deviceSupportRequestLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<DeviceSupportRequestLoaderResponse> => {
  const companyId = getCompanyIdFromRequest(request);
  const [companyInfo, pendingSubscriptionActions, subscription] = await Promise.all([
    fetchCompanyInfo(companyId),
    pendingSubscriptionActionsLoader(companyId),
    subscriptionLoader([SubscriptionType.DEVICE], params.subscriptionId, companyId),
  ]);
  return { companyInfo, pendingSubscriptionActions, subscription };
};

export const deviceAddonLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<DeviceAddonLoaderResponse> => {
  const companyId = getCompanyIdFromRequest(request);
  const [pendingSubscriptionActions, subscription] = await Promise.all([
    pendingSubscriptionActionsLoader(companyId),
    subscriptionLoader([SubscriptionType.DEVICE], params.subscriptionId, companyId),
  ]);
  return { pendingSubscriptionActions, subscription };
};

export const getDnsSubscriptions = async (args: LoaderFunctionArgs) => {
  return subscriptionsLoader(SubscriptionCategory.DOMAIN, args);
};

export const contactsLoader = ({ request }: LoaderFunctionArgs) => {
  const { sort, offset, itemsPerPage, ...rest } = getSearchParams(request);

  return fetchContacts(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      offset: Number(offset),
      limit: itemsPerPage,
      sort: getContactSort(sort),
      ...rest,
    })
  );
};

export const subscriptionActionLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<SubscriptionActionsResponse> => {
  if (params.requestId === undefined) {
    throw new Error('missing requestId');
  }
  return fetchSubscriptionAction(params.requestId, getCompanyIdFromRequest(request));
};

export const getSubscriptionActions = ({ request }: LoaderFunctionArgs) => {
  const { offset, itemsPerPage, ...rest } = getSearchParams(request);

  return fetchSubscriptionActions(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      offset: Number(offset),
      limit: itemsPerPage,
      ...rest,
    })
  );
};

export const onlineModelsForCartItemsLoader = async () => {
  const fromCartItemsJson: CartItemType[] = JSON.parse(getShoppingCart());
  const onlineModelCodeGuids = [...new Set(fromCartItemsJson.map(cartItem => cartItem.bundle.guid))];
  return {
    onlineModels: await fetchOnlineModels(onlineModelCodeGuids),
    onlineModelsHeadersForSalesProducts: await fetchOnlineModelHeaders([CommercialProductType.SALES_PRODUCT]),
  };
};

export const onlineModelsForCatalogLoader = async () => {
  const [phones, accessories, tablets, computers, networkEquipment] = await Promise.all([
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.PHONE),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.ACCESSORIES),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.TABLET),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.COMPUTERS),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.NETWORK_EQUIPMENT),
  ]);
  return {
    phones,
    accessories,
    tablets,
    computers,
    networkEquipment,
  };
};

export const virtualCatalogsLoader = ({ request }: LoaderFunctionArgs) => {
  const { params, offset, sort, itemsPerPage, ...rest } = getSearchParams(request);
  return fetchVirtualCatalogs(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      productType: params.get('productType'),
      contractPeriod: params.get('contractPeriod'),
      status: params.get('status'),
      limit: itemsPerPage,
      offset: Number(offset),
      sort: sort || 'publishedOrDraftLastModified',
      ...rest,
    })
  );
};

export const virtualCatalogsForAllAccountsLoader = () => {
  return fetchVirtualCatalogs(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      searchAllAccounts: true,
    })
  );
};

export const chatHistoryLoader = () => {
  const sessionId = getAiChatSessionId();
  return sessionId ? getMessagesFromChatHistory(sessionId) : Promise.resolve({ sessionId: '', messages: [] });
};

export const invoiceLoader = async ({ params, request }: LoaderFunctionArgs): Promise<InvoiceLoaderResponse> => {
  if (params.invoiceId === undefined) {
    throw new Error('missing invoiceId');
  }
  const companyId = getCompanyIdFromRequest(request);
  const [invoicesResponse, billChannels, billingAccountsResponse] = await Promise.all([
    fetchInvoice(params.invoiceId, companyId),
    fetchBillChannels(),
    fetchBillingAccounts({ offset: 0, useSearchService: true }, companyId),
  ]);
  const invoice = invoicesResponse?.invoices?.[0] as Invoice;
  if (!invoice) {
    throw new Response('Invoice not found', { status: 404 });
  }
  const openSupportCases = await fetchOpenSupportCases({ search: invoice.invoiceDisplayId }, companyId);
  const mappedSupportCases =
    openSupportCases?.searchResults?.map((res: SupportCasesSearchResponse) => res.result) || [];
  const billingAccount =
    billingAccountsResponse?.billingAccounts?.find(ba => ba.billingAccountId === invoice.billingAccountId) ??
    invoicesResponse.billingAccounts?.find(ba => ba.billingAccountId === invoice.billingAccountId);
  return {
    invoice,
    billChannels: billChannels || [],
    openSupportCases: mappedSupportCases,
    billingAccount: billingAccount,
  };
};

export interface InvoicesLoaderData {
  invoices: InvoicesResponse;
  supportCases: SupportCasesResponse;
}

export const invoicesLoader = async ({ request }: LoaderFunctionArgs): Promise<InvoicesLoaderData> => {
  const { itemsPerPage, offset, sort, order, search } = getSearchParams(request);
  return {
    invoices: await fetchInvoices({
      ...defaultRequest,
      useSearchService: true,
      search,
      order,
      limit: itemsPerPage,
      offset: Number(offset),
      sort,
    }),
    supportCases: await fetchOpenSupportCases({ search, order: 'desc', offset: 0 }),
  };
};

export interface DocumentsLoaderData {
  documents: InvoiceDocumentsResponse;
}

export const documentsLoader = async ({ request }: LoaderFunctionArgs): Promise<DocumentsLoaderData> => {
  const { itemsPerPage, offset, sort, order, search } = getSearchParams(request);
  return {
    documents: await fetchInvoiceDocuments({
      ...defaultRequest,
      useSearchService: true,
      offset: Number(offset),
      sort,
      order,
      search,
      limit: itemsPerPage,
    }),
  };
};
