import {
  CLEARABLE_CLASS,
  DEBOUNCE_TIMEOUT,
  FOCUSED_CLASS,
  HIGHLIGHTED_CLASS,
  MOVEMENT_SPEED,
  QUERY_MIN_LENGTH,
} from './config.js';
import {
  createOptionId,
  createOptionValueAndId,
  eventTargetToHtmlListElement,
  filterOptions,
  findEarlierSibling,
  findFirstSibling,
  findLastSibling,
  findLaterSibling,
  findNextSibling,
  findPreviousSibling,
  getInitialOption,
  getInitialValue,
  mergeTwoLists,
  resetValuesToUndefined,
  sortOptions,
} from './utils.js';
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDebounce } from '../../common/hooks/useDebounce.js';
import type { AutoCompleteProps } from './AutoComplete.js';
import type { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent } from 'react';
import type {
  ConfigurationObject,
  ExternalInputRef,
  InputRef,
  InputTypes,
  OptionRef,
  OptionTypes,
  OptionsRef,
  OptionsTypes,
} from './config.js';

export interface InputValueAndId {
  value: string;
  id?: string | number;
}

interface UseAutoCompleteReturnValues<T> {
  id: string;
  config: ConfigurationObject;
  error?: string;
  isOpen: boolean;
  isFilter: boolean;
  isFocused: boolean;
  isLoading: boolean;
  inputValueAndId: InputValueAndId;
  optionValue?: T;
  inputRef: InputRef;
  optionRef: OptionRef;
  optionsRef: OptionsRef;
  options: T[];
  filteredOptions: T[];
  getControlProps: () => {
    onKeyDown: (event: KeyboardEvent) => void;
    onMouseDown: (event: MouseEvent) => void;
  };
  getInputProps: () => {
    onBlur: (event: FocusEvent) => void;
    onFocus: () => void;
    onChange: (event: ChangeEvent) => void;
    onMouseDown: (event: MouseEvent) => void;
  };
  getClearProps: () => {
    onMouseDown: (event: MouseEvent) => void;
  };
  getChevronProps: () => {
    onMouseDown: (event: MouseEvent) => void;
  };
  getOptionProps: (option: T) => {
    onMouseMove: (event: MouseEvent) => void;
    onClick: (event: MouseEvent) => void;
  };
  getNoOptionProps: () => {
    onMouseDown: (event: MouseEvent) => void;
  };
}

const defaultConfiguration = {
  isClearable: true,
  isSortable: true,
  movementSpeed: MOVEMENT_SPEED,
  debounceTimeout: DEBOUNCE_TIMEOUT,
  queryMinLength: QUERY_MIN_LENGTH,
};

const useCustomInputRef = (externalRef: ExternalInputRef) => {
  const internalRef = useRef<InputTypes>(null);

  useImperativeHandle(externalRef, () => ({
    focus: () => {
      internalRef.current?.focus();
    },
    blur: () => {
      internalRef.current?.blur();
    },
  }));

  return internalRef;
};

export const useAutoComplete = <T>(props: AutoCompleteProps<T>): UseAutoCompleteReturnValues<T> => {
  const config = useMemo(() => Object.assign({}, defaultConfiguration, props.config || {}), [props.config]);
  const [options, setOptions] = useState(sortOptions(props.options, props.getDisplayValue, config));
  const [inputValueAndId, setInputValueAndId] = useState(getInitialValue(props));
  const [optionValue, setOptionValue] = useState(getInitialOption(props));
  const [isOpen, setIsOpen] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const filteredOptions = filterOptions(isFilter, inputValueAndId.value, options, props.getDisplayValue);
  const inputRef = useCustomInputRef(props.inputRef);
  const optionRef = useRef<OptionTypes>(null);
  const optionsRef = useRef<OptionsTypes>(null);
  const eventRef = useRef<KeyboardEvent | MouseEvent | null>();

  const fetchFn = props.async?.fetchFn || (async () => []);
  const debounce = useDebounce(
    async (query: string) => {
      setIsLoading(true);
      const list = await fetchFn(query);
      setIsLoading(false);

      if (list.length === 0) {
        return;
      }

      const rawList = options.length === 0 ? list : mergeTwoLists(options, list, props.getUniqueId);
      const sortedList = sortOptions(rawList, props.getDisplayValue, config);
      setOptions(sortedList);
    },
    config.debounceTimeout,
    args => args[0].length >= config.queryMinLength
  );

  const switchOption = useCallback(
    (event: MouseEvent | KeyboardEvent | ChangeEvent, oldRef: OptionRef, newRef: OptionRef) => {
      const index = Number(newRef.current?.getAttribute('data-option-index'));
      !isNaN(index) && inputRef.current?.setAttribute('aria-activedescendant', createOptionId(props.id, index));
      oldRef.current?.classList.remove(HIGHLIGHTED_CLASS);
      newRef.current?.classList.add(HIGHLIGHTED_CLASS);
      // Prevent any scrolling with moving mouse
      if (event.type !== 'mousemove') {
        newRef.current?.scrollIntoView({ block: 'nearest' });
      }
      optionRef.current = newRef.current;
    },
    [inputRef, props.id]
  );

  useEffect(() => {
    setOptions(sortOptions(props.options, props.getDisplayValue, config));
  }, [config, props.getDisplayValue, props.options]);

  useEffect(() => {
    const event = eventRef.current;
    if (isOpen) {
      if (event?.type === 'keydown') {
        const keyboardEvent = event as KeyboardEvent;
        if (keyboardEvent?.key === 'ArrowDown') {
          switchOption(
            keyboardEvent,
            { current: null },
            optionRef.current ? optionRef : findFirstSibling({ optionsRef })
          );
        } else if (keyboardEvent?.key === 'ArrowUp') {
          switchOption(
            keyboardEvent,
            { current: null },
            optionRef.current ? optionRef : findLastSibling({ optionsRef })
          );
        }
      } else if (event?.type === 'mousedown') {
        switchOption(event, { current: null }, optionRef);
      }
      eventRef.current = null;
    }
  }, [isOpen, switchOption]);

  // ---------- Utilities ----------
  const closeOption = () => {
    setIsOpen(false);
    optionRef.current = null;
    optionsRef.current = null;
    inputRef.current?.removeAttribute('aria-activedescendant');
  };
  const selectOption = (event: ChangeEvent | KeyboardEvent | MouseEvent, option: T) => {
    if (option) {
      setInputValueAndId(createOptionValueAndId(props, option));
      setOptionValue(option);
      config.isClearable && inputRef.current?.parentElement?.classList.add(CLEARABLE_CLASS);
      if (event.type !== 'blur') {
        props.onInputChange(event, option);
      }
    }
    closeOption();
  };
  const openOption = (event: MouseEvent | KeyboardEvent) => {
    eventRef.current = event;
    inputRef.current?.focus();
    setIsOpen(true);
  };
  const clearInput = (event: ChangeEvent | KeyboardEvent | MouseEvent) => {
    setIsFilter(false);
    if (config.isClearable) {
      inputRef.current?.parentElement?.classList.remove(CLEARABLE_CLASS);
      setOptionValue(undefined);
      setInputValueAndId({ value: '', id: config.isClearable ? inputValueAndId.id : undefined });
      if (optionValue) {
        props.onInputChange(event, resetValuesToUndefined(optionValue));
      }
    }
  };
  const removeOptionWithDeleteBackSpaceOrCut = (event: ChangeEvent) => {
    const { inputType } = event.nativeEvent as InputEvent;
    const inputTypes = ['deleteContentBackward', 'deleteContentForward', 'deleteByCut'];
    if (inputTypes.some(i => i === inputType)) {
      clearInput(event);
    }
  };

  // ---------- Control handlers ----------
  const prepareToSwitchOneOption = (event: KeyboardEvent) => {
    if (isOpen) {
      if (event.key === 'ArrowUp') {
        return switchOption(event, optionRef, findPreviousSibling({ optionRef, optionsRef }));
      }
      if (event.key === 'ArrowDown') {
        return switchOption(event, optionRef, findNextSibling({ optionRef, optionsRef }));
      }
    } else {
      openOption(event);
    }
  };

  const prepareToSwitchManyOptions = (event: KeyboardEvent) => {
    if (event.key === 'PageUp') {
      return switchOption(event, optionRef, findEarlierSibling({ optionRef, optionsRef }, config.movementSpeed));
    }
    if (event.key === 'PageDown') {
      return switchOption(event, optionRef, findLaterSibling({ optionRef, optionsRef }, config.movementSpeed));
    }
  };

  const prepareToSwitchAllOptions = (event: KeyboardEvent) => {
    if (event.key === 'Home') {
      return switchOption(event, optionRef, findFirstSibling({ optionsRef }));
    }
    if (event.key === 'End') {
      return switchOption(event, optionRef, findLastSibling({ optionsRef }));
    }
  };

  const prepareToSelectOption = (event: KeyboardEvent) => {
    event.preventDefault();
    if (isOpen) {
      const index = Number(optionRef.current?.getAttribute('data-option-index'));
      if (!isNaN(index)) {
        selectOption(event, filteredOptions[index]);
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    switch (event.key) {
      case 'Home':
        return prepareToSwitchAllOptions(event);
      case 'End':
        return prepareToSwitchAllOptions(event);
      case 'PageUp':
        return prepareToSwitchManyOptions(event);
      case 'PageDown':
        return prepareToSwitchManyOptions(event);
      case 'ArrowDown':
        return prepareToSwitchOneOption(event);
      case 'ArrowUp':
        return prepareToSwitchOneOption(event);
      case 'Enter':
        return prepareToSelectOption(event);
      case 'Escape':
        return closeOption();
      default:
    }
  };

  const handleMouseDown = (event: MouseEvent & { target: Element }) => {
    const index = Number(event.target.getAttribute('data-option-index'));
    if (event.target.getAttribute('id') === createOptionId(props.id, index)) {
      // Prevent input blur when interacting with the combobox
      event.preventDefault();
    }
  };

  // ---------- Input handlers ----------
  const handleInputFocus = () => {
    setIsFocused(true);
    inputRef.current?.parentElement?.classList.add(FOCUSED_CLASS);
  };
  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    inputRef.current?.parentElement?.classList.remove(FOCUSED_CLASS);
    props.onInputBlur && props.onInputBlur(event);
    if (optionValue) {
      selectOption(event, optionValue);
    } else {
      closeOption();
    }
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValid = value.length > 0;

    debounce(value)?.then();

    if (!isValid) {
      removeOptionWithDeleteBackSpaceOrCut(event);
    }

    if (isValid !== isFilter) {
      setIsFilter(isValid);
    }

    if (!isOpen) {
      setIsOpen(true);
    }

    if (value !== inputValueAndId.value) {
      setInputValueAndId({
        value: value ? value : '',
        id: config.isClearable ? undefined : inputValueAndId.id,
      });
    }
  };
  const handleInputMouseDown = (event: MouseEvent) => {
    if (event.button === 0) {
      if (isOpen && !optionValue) {
        closeOption();
        clearInput(event);
      } else {
        openOption(event);
      }
    }
  };
  const handleCloseMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    if (event.button === 0) {
      closeOption();
      clearInput(event);
    }
  };
  const handleChevronMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    if (event.button === 0) {
      if (isOpen) {
        if (!optionValue) {
          clearInput(event);
        }
        closeOption();
      } else {
        openOption(event);
      }
    }
  };

  // ---------- Option handlers ----------
  const handleNoOptionMouseMove = (event: MouseEvent) => {
    // Prevent input blur when interacting with the "no options" content
    event.preventDefault();
  };
  const handleOptionMouseMove = (event: MouseEvent<HTMLLIElement>) => {
    if (optionRef?.current !== event.target) {
      switchOption(event, optionRef, eventTargetToHtmlListElement(event));
    }
  };
  const handleOptionClick = (event: MouseEvent, option: T) => {
    selectOption(event, option);
  };

  return {
    id: props.id,
    error: props.error,
    config,
    isOpen,
    isFilter,
    isFocused,
    isLoading,
    inputValueAndId,
    optionValue,
    inputRef,
    optionRef,
    optionsRef,
    options,
    filteredOptions,
    getControlProps: () => ({
      onKeyDown: handleKeyDown,
      onMouseDown: handleMouseDown,
    }),
    getInputProps: () => ({
      onBlur: handleInputBlur,
      onFocus: handleInputFocus,
      onChange: handleInputChange,
      onMouseDown: handleInputMouseDown,
      ref: inputRef,
    }),
    getClearProps: () => ({
      onMouseDown: handleCloseMouseDown,
    }),
    getChevronProps: () => ({
      onMouseDown: handleChevronMouseDown,
    }),
    getOptionProps: option => ({
      onMouseMove: handleOptionMouseMove,
      onClick: event => handleOptionClick(event, option),
    }),
    getNoOptionProps: () => ({
      onMouseDown: handleNoOptionMouseMove,
    }),
  };
};
