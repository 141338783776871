import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { ContactDetailsForm } from './ContactDetailsForm.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { Loading } from '../Loading/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { omaElisaForCompaniesMsg, t, userNameMsg, userTextMsg, usersMsg } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useSelector } from 'react-redux';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';
import type { Contact } from '../../generated/api/models.js';
import type { State } from '../../selfservice/common/store.js';

import './ContactDetails.scss';

export interface ContactDetailsProps {
  contact?: Contact;
}

const getDisplayName = (contact?: Contact) => {
  if (contact?.person) {
    return `${contact.person.firstName} ${contact.person.lastName}`;
  } else if (contact?.commonFunction) {
    return contact?.commonFunction.name;
  } else {
    return '...';
  }
};

const getHeadingTop = (contact: Contact | undefined) => {
  switch (contact?.contactType) {
    case 'PERSON':
      return t.LUZM(userTextMsg);
    case 'LIMITED_INFORMATION_PERSON':
      return t.J23G(userNameMsg);
    case 'COMMON_FUNCTION':
      return t.G8FE('Common function');
    default:
      return '...';
  }
};

export const ContactDetails = ({ contact }: ContactDetailsProps) => {
  const { config } = useSelector(
    (state: State) => ({
      config: state.config,
    }),
    deepEqual
  );
  const { authenticatedUser } = useAuth();
  const { companyId } = useSearchParams<{ companyId: string }>();

  const displayName = getDisplayName(contact);
  const headingTop = getHeadingTop(contact);
  const backLinkTitle = t.BE4A(usersMsg);
  const detailsWrapperId = `contact-details-${contact?.contactId}`;

  const breadCrumbPaths: BreadCrumbList =
    contact?.person || contact?.commonFunction
      ? [
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: backLinkTitle, path: paths.COMPANY_INFO_CONTACTS },
          { name: displayName },
        ]
      : [{ name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME }, { name: backLinkTitle }];
  return (
    <DetailsWrapper
      classes={['of-contact-details']}
      content={!contact ? <Loading /> : <ContactDetailsForm config={config} contact={contact} />}
      detailsTop={<BreadCrumbs breadCrumbPaths={breadCrumbPaths} />}
      id={detailsWrapperId}
      heading={displayName}
      headingBottom={getCompanyName(authenticatedUser, companyId)}
      heroPicto="cl-user"
      styledHeadingTop={<span className="ds-text--lead ds-color--blue-800">{headingTop}</span>}
      styledHeading={<h1 className="of-contact-details__h1 ds-color--blue-800">{displayName}</h1>}
    />
  );
};
